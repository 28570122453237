// DefaultLayout.js
import React, { Suspense, useState } from "react";
import { Button, Layout, Menu } from "antd";
import { Outlet } from "react-router-dom";
import { Navbar } from "../../Navbar";
import Sidebar from "../../Navbar/Components/Sidebar";
import AddressDrawer from "./AddressDrawer";

const { Content, Header } = Layout;

const DefaultLayout = () => {
  const [finalAddress, setFinalAddress] = useState("");
  const [collapsed, setCollapsed] = useState(true);
  return (
    <Layout>
      <Sidebar collapsed={collapsed} setCollapsed={setCollapsed} />
      <Layout>
        <Navbar finalAddress={finalAddress} collapsed={collapsed} setCollapsed={setCollapsed} />
        <Content
          style={{
            padding: "0px 24px",
          }}
        >
            <Outlet />
        </Content>
      </Layout>
      <AddressDrawer setFinalAddress={setFinalAddress} />
    </Layout>
  );
};

export default DefaultLayout;
