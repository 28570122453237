import React, { useEffect, useState } from "react";
import { Alert, Button, Card, Col, Divider, Modal, Row } from "antd";
import FinalParentDetails from "./FinalParentDetails";
import FinalPetDetails from "./FinalPetDetails";
import FinalSelectedServices from "./FinalSelectedServices";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { bookAppointmentAction } from "../State/bookAppointmentActions";
import RightPayment from "./RightPayment";
import useCheckEffects from "./useCheckEffects";
import useCheckAllDetails from "./useCheckAllDetails";
import Spinner from "../../Spinner/Spinner";
import useFinalBookAppointmentEffects from "./useFinalBookAppointmentEffects";
import FinalBookAppointmentButtons from "./FinalBookAppointmentButtons";

/**
 * FinalBookAppointmentEditInfoPage Component
 *
 * This component is the final step in the appointment booking flow, displaying
 * the user's selected appointment details (parent, pet, services) and handling
 * the appointment booking process.
 *
 * Main Functionalities:
 * - Displays selected appointment details: parent, pet, services, and payment info.
 * - Manages countdown timer for appointment slot expiration.
 * - Shows payment status and error alerts.
 * - Manages session timeout modal if the booking slot has expired.
 * - Provides navigation buttons for completing or going back in the booking flow.
 */

const FinalBookAppointmentEditInfoPage = () => {
  const location = useLocation();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [slotsExpired, setSlotsExpired] = useState(false);
  const query = new URLSearchParams(location.search);
  const status = query.get("status");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isPaymentLoading, setIsPaymentLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const { bookappointmentdetails } = useSelector(
    (str) => str.bookAppointmentReducer
  );
  const { isLoading, isLoadingFinalAppointDetails } = useSelector(
    (str) => str.loadingReducer
  );
  const { isLoadingParentDetails } = useSelector(
    (str) => str.additionalloadingReducer
  );
  const { parentBoardingDetails } = useSelector((str) => str.profileReducer);

  const bookingTime = bookappointmentdetails?.bookingDetails?.countDown;

  const handleGoBack = () => {
    setIsModalVisible(false);
    navigate("/book-appointment/step-2"); // Navigate to the previous page
  };

  const { calculateTimeRemaining } = useFinalBookAppointmentEffects({
    setIsModalVisible,
    setSlotsExpired,
  });

  useCheckEffects("Book Appointment");

  useEffect(() => {
    return () => {
      dispatch(bookAppointmentAction.updatedDetailsresetEvent());
    };
  }, []);

  // Custom hook to perform checks and return a result component if necessary
  const checkResult = useCheckAllDetails();
  if (checkResult) {
    return checkResult;
  }

  if (
    isLoading ||
    isLoadingFinalAppointDetails ||
    isLoadingParentDetails ||
    loading
  )
    return <Spinner />;

  const handleOk = () => setIsModalVisible(false);

  return (
    <>
      <div style={{ position: "relative" }}>
        {/* final details of parent, pet, services and payment in the right of the page */}
        <Row gutter={[16, 16]} justify={"center"} style={{ marginTop: "30px" }}>
          <Col xs={24} sm={24} md={24} lg={15}>
            {status === "failed" && (
              <Alert
                message="Payment Failed"
                description="Unfortunately, your payment could not be processed. Please try again."
                type="error"
                showIcon
              />
            )}
            <Card hoverable>
              <FinalParentDetails
                details={bookappointmentdetails?.currentParent}
              />
              <FinalPetDetails
                details={bookappointmentdetails?.petDetails}
                parentBoardingDetails={parentBoardingDetails?.pets}
              />
              <FinalSelectedServices
                details={bookappointmentdetails?.bookingDetails}
                address={bookappointmentdetails?.currentParent?.address}
              />
            </Card>
          </Col>

          <Col xs={0} sm={0} md={0} lg={1} style={{ flex: 0 }}>
            <Divider type="vertical" style={{ height: "100%" }} />
          </Col>

          <Col
            xs={24}
            lg={8}
            style={{ position: "sticky", top: "100px", height: "max-content" }}
          >
            <RightPayment
              details={bookappointmentdetails?.bookingDetails}
              rewardDetails={bookappointmentdetails?.currentParent?.rewards}
              slotsExpired={slotsExpired}
              calculateTimeRemaining={calculateTimeRemaining}
            />
          </Col>
        </Row>

        {/* back and next buttons */}
        <FinalBookAppointmentButtons
          isPaymentLoading={isPaymentLoading}
          slotsExpired={slotsExpired}
          setIsPaymentLoading={setIsPaymentLoading}
          setLoading={setLoading}
        />
      </div>

      <Modal
        title="Session Timeout"
        open={isModalVisible}
        onCancel={handleOk}
        onOk={handleOk}
        footer={
          <Button type="primary" onClick={handleGoBack}>
            Go Back
          </Button>
        }
      >
        <p>
          {`Sorry, your booking session has timed out due to expired slots. Please
          go back to select a new slot or create a new booking. ${
            bookappointmentdetails?.bookingDetails?.redeem === true
              ? "Your redeemed reward points are restored"
              : ""
          }`}
        </p>
      </Modal>
    </>
  );
};
export default FinalBookAppointmentEditInfoPage;
