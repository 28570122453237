import { CaretLeftFilled, CaretRightFilled } from '@ant-design/icons';
import { Button, Card, Col, Form, Input, Row } from 'antd';
import React from 'react'
import { useSelector } from 'react-redux';

const FinalPageToDeleteAccount = ({ onFinish, loading, setCurrent, setShow }) => {
    const [deleteForm] = Form.useForm();
    const { userDetails } = useSelector(str => str.navbarReducer);

    return (
        <Row
            justify={"center"}
            style={{ alignItems: "center", height: "80vh" }}
        >
            <Col xs={22} sm={22} md={16} lg={12} xl={10}>
                <Card title={userDetails?.email} hoverable>
                    <Form form={deleteForm} layout="vertical" onFinish={onFinish}>
                        <Form.Item
                            label={
                                "To confirm, enter otp sent to your registered email address in the box below"
                            }
                            name={"otp"}
                            style={{ textAlign: "center" }}
                            rules={[
                                {
                                    required: true,
                                    message: "Please input your otp",
                                },
                            ]}
                        >
                            <Input.OTP />
                        </Form.Item>
                        <Row
                            style={{
                                marginTop: "1.2em",
                                justifyContent: "space-between",
                            }}
                        >
                            <Button
                                size="small"
                                danger
                                icon={<CaretLeftFilled />}
                                onClick={() => {
                                    setCurrent(2);
                                    setShow({ final: false, form: true, verified: false });
                                }}
                            >
                                Previous
                            </Button>
                            <Button
                                size="small"
                                danger
                                icon={<CaretRightFilled />}
                                iconPosition="end"
                                htmlType="submit"
                                loading={loading}
                            >
                                Verify OTP
                            </Button>
                        </Row>
                    </Form>
                </Card>
            </Col>
        </Row>
    )
}

export default FinalPageToDeleteAccount
