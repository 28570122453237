import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchPaymentActions } from "./State/actions";
import { useRoute } from "../context/RouteContext";
import dayjs from "dayjs";
import { Table, Typography } from "antd";
import PaymentHeader from "./Components/PaymentHeader";
import { debounceFun } from "../Common/Components/DebounceSearchFun";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
const { Text } = Typography;

const FetchPayments = () => {
  const dispatch = useDispatch();
  const { fetchPayments, fetchPaymentsErr, totalFetchPayments } = useSelector(
    (str) => str.fetchPaymentsReducer
  );
  const [searchParams] = useSearchParams();

  const { isLoadingPaymentLinks } = useSelector(
    (str) => str.additionalloadingReducer
  );
  const navigate = useNavigate();
  const location = useLocation();
  console.log(fetchPayments, "fetchPayments");
  const { setSelectedKey } = useRoute();
  const [page, setPage] = useState(1);

  const getPaymentsfn = (page, value) => {
    if (value) {
      dispatch(fetchPaymentActions.getPaymentsEvent(page, value));
    } else if (searchParams.size === 0) {
      dispatch(fetchPaymentActions.getPaymentsEvent(page));
    } else {
      dispatch(
        fetchPaymentActions.getPaymentsEvent(
          page,
          searchParams.get("search"),
          searchParams.get("sort")
        )
      );
    }
  };

  useEffect(() => {
    getPaymentsfn(1);
    setSelectedKey("FETCH_ALL_BOOKINGS_PAYMENTS");
  }, [dispatch]);

  const searchClientFunc = debounceFun((value) => {
    const searchParams = new URLSearchParams(location.search);
    searchParams.set("search", value);
    navigate(`?${searchParams.toString()}`);
    getPaymentsfn(1, value);
  }, 1000);

  useEffect(() => {
    return () => setPage(1);
  }, []);

  const columns = [
    {
      title: "Created On",
      key: "createdAt",
      width:"20%",
      render: (_, { createdAt }) => (
        <Text>{dayjs(createdAt).format("MMM D, YYYY h:mm A")}</Text>
      ),
    },
    {
      title: "User Info",
      key: "userInfo",
      width:"30%",
      render: (_, { firstName, lastName, email, contactNumber }) => (
        <>
          <Text style={{ display: "block" }}>{firstName + lastName}</Text>
          <Text style={{ display: "block" }} strong>
            {email}
          </Text>
          <Text style={{ display: "block" }}>{contactNumber}</Text>
        </>
      ),
    },
    {
      title: "Booking ID",
      dataIndex: "bookingUniqueId",
      key: "bookingUniqueId",
      width:"10%"
    },
    {
      title: "Payment Info",
      key: "paymentInfo",
      width:"30%",
      render: (
        _,
        { paymentStatus, payment_id, subscriptionStatus, subscription_id }
      ) => (
        <>
          <Text style={{ display: "block" }}>
            Payment Status: {paymentStatus}
          </Text>
          <Text style={{ display: "block" }} strong>
            {" "}
            Payment ID: {payment_id}
          </Text>
          <Text style={{ display: "block" }}>
            Subscription Status: {subscriptionStatus}
          </Text>
          <Text style={{ display: "block" }} strong>
            {" "}
            Subscription ID: {subscription_id}
          </Text>
        </>
      ),
    },
  ];

  const loadMoreData = (page) => {
    if (!isLoadingPaymentLinks && fetchPayments.length < totalFetchPayments)
      getPaymentsfn(page);
    else setPage(1);
  };

  return (
    <div>
      <Table
        pagination={false}
        bordered
        size="small"
        dataSource={fetchPayments}
        columns={columns}
        loading={isLoadingPaymentLinks}
        rowKey={(record) => record._id}
        onScroll={(e) => {
          const { scrollTop, scrollHeight, clientHeight } = e.target;
          if (scrollTop + clientHeight >= scrollHeight - 50) {
            if (!isLoadingPaymentLinks) {
              const nextPage = page + 1;
              setPage(nextPage);
              loadMoreData(nextPage);
            }
          }
        }}
        title={() => (
          <PaymentHeader
            searchClientFunc={searchClientFunc}
            setPage={setPage}
          />
        )}
        scroll={{ y: 500 }}
      />
    </div>
  );
};

export { FetchPayments };
