import React, { Suspense, useEffect, useState } from "react";
import { Layout } from "antd";
import { Routes, Route } from "react-router-dom";
import { Dashboard } from "../Dashboard";
import { Login } from "../Signup";
import Signup from "../Signup/Signup";
import ForgotPassword from "../Signup/ForgotPassword";
import ResetPassword from "../Signup/ResetPassword";
import VerifyPage from "../Signup/VerifyPage";
import { PrivateRoute } from "./Components/PrivateRoute";
import { NotFound } from "../Common/Components/NotFound";
import { ServiceDetails } from "../ServiceDetails";
import { Trainers } from "../Trainers";
import { Veterinarians } from "../Veterinarians";
import { Settings } from "../Settings";
import { BookAppointmentRoute } from "./Components/BookAppointmentRoute";
import Details from "../BookAppointment/Components/Details";
import FinalBookAppointmentEditInfoPage from "../BookAppointment/Components/FinalBookAppointmentEditInfoPage";
import { BookAppointment } from "../BookAppointment";
import AddAddress from "../Dashboard/Components/AddAddress";
import Tracking from "../Common/Components/Tracking";
import { GoogleAuthCallback } from "../Signup/Components/GoogleAuthCallback";
import Navigation from "../Common/Components/Navigation";
import BookingUserDetails from "../ServiceDetails/Components/BookingUserDetails";
import SwitchRole from "../Signup/Components/SwitchRole";
import Help from "../Help/Help";
import AddQuestion from "../Help/Components/AddQuestion";
import { Search } from "../Search/Search";
import CaptainsBookings from "../Captain/Components/CaptainsBookings";
import { Captain } from "../Captain/Captain";
import BookingsFortheDay from "../Captain/Components/BookingsFortheDay";
import { SocketProvider } from "../context/SocketContext";
import { CancellationOfBookings } from "../CancellationOfBookings/CancellationOfBookings";
import { BoardingCenter } from "../BoardingCenter/BoardingCenter";
import AddBoardingCenter from "../BoardingCenter/Components/AddBoardingCenter";
import { Support } from "../Support";
import PaymentSuccess from "../Common/Components/PaymentSuccess";
import BillingActivity from "../Settings/Components/BillingActivity";
import DefaultLayout from "./Components/DefaultLayout";
import NavbarOnlyLayout from "./Components/NavbarOnlyLayout";
import Invoice from "../Common/Components/Invoice";
import { PaymentLinks } from "../PaymentLinks/PaymentLinks";
import { CrudPlans } from "../CrudPlans";
import PendingPlans from "../CrudPlans/Components/PendingPlans";
import { Kennels } from "../Kennels";
import { VaccineCertificates } from "../VaccineCertificates";
import CreateCityForm from "../CrudPlans/Components/CreateCityForm";
import CreateStateForm from "../CrudPlans/Components/CreateStateForm";
import Users from "../Users/Users";
import CreateUser from "../Users/Components/CreateUser";
import { News } from "../News";
import AddBlog from "../News/Components/AddBlog";
import Spinner from "../Spinner/Spinner";
import { PetBreedsForMarketing } from "../PetBreedsForMarketing";
import AddPetBreedForMarketing from "../PetBreedsForMarketing/Components/AddPetBreedForMarketing";
import { PrivateRouteForPublicRoutes } from "./Components/PrivateRouteForPublicRoutes";
import NoInternet from "../Common/Components/NoInternet";
import DeleteAccount from "../Common/Components/DeleteAccount";
import { FetchPayments } from "../FetchPayments";
import RazorpayPayments from "../FetchPayments/Components/RazorpayPayments";
import ReVerify from "../Signup/Components/ReVerify";
const { Content } = Layout;

function App() {

  const [isConnected, setIsConnected] = useState(true);

  useEffect(() => {
    window.addEventListener("online", (f) => {
      setIsConnected(true);
    });
    window.addEventListener("offline", () => {
      setIsConnected(false);
    });
  }, []);

  if (!isConnected) {
    return <NoInternet />
  }

  return (
    <div>
      <Layout>
        <Content>
          <Routes>
            <Route element={<PrivateRoute layout={DefaultLayout} allowedRoles={["ADMIN"]} />}>

              <Route path="/captains" element={<Captain />} />

              <Route exact path="/services/:type" element={<ServiceDetails />} />

              <Route exact path="/cancellationRequests" element={<CancellationOfBookings />} />

              <Route exact path="/userDetails/:id" element={<BookingUserDetails />} />

              <Route exact path="/tickets" element={<Support />} />

              <Route exact path="/paymentLinks" element={<PaymentLinks />} />

              <Route exact path="/captains/:bookingId" element={<Captain />} />

              <Route exact path="/captainsBookings/:captainUniqueId" element={<CaptainsBookings />} />

              <Route exact path="/kennels" element={<Kennels />} />

              <Route exact path="/vaccineCertificates" element={<VaccineCertificates />} />

            </Route>

            <Route element={<PrivateRoute layout={DefaultLayout} allowedRoles={["ADMIN", "SUPER_ADMIN"]} />}>

              <Route exact path="/boardingCenter/:id" element={<AddBoardingCenter />} />

              <Route exact path="/createUser" element={<CreateUser />} />

            </Route>

            <Route element={<PrivateRoute layout={DefaultLayout} allowedRoles={["ADMIN", "SUPER_ADMIN", "CITY_ADMIN", "STATE_ADMIN",]} />}>

              <Route exact path="/plans" element={<CrudPlans />} />

              <Route exact path="/users" element={<Users />} />

            </Route>

            <Route element={<PrivateRoute layout={DefaultLayout} allowedRoles={["SUPER_ADMIN", "STATE_ADMIN"]} />}>

              <Route exact path="/city" element={<CreateCityForm />} />

            </Route>

            <Route element={<PrivateRoute layout={DefaultLayout} allowedRoles={["SUPER_ADMIN"]} />}>

              <Route exact path="/help" element={<Help />} />

              <Route exact path="/help/:id" element={<AddQuestion />} />

              <Route exact path="/addQuestion" element={<AddQuestion />} />

              <Route exact path="/allBoardingCenters" element={<BoardingCenter />} />

              <Route exact path="/pendingPlans" element={<PendingPlans />} />

              <Route exact path="/addBoardingCenter" element={<AddBoardingCenter />} />

              <Route exact path="/state" element={<CreateStateForm />} />

              <Route exact path="/news" element={<News />} />

              <Route exact path="/addBlog" element={<AddBlog />} />

              <Route exact path="/addBlog/:id" element={<AddBlog />} />

              <Route exact path="/pets" element={<PetBreedsForMarketing />} />

              <Route exact path="/pet/:id" element={<AddPetBreedForMarketing />} />

              <Route exact path="/addPet" element={<AddPetBreedForMarketing />} />

              <Route exact path="/payments" element={<FetchPayments />} />

              <Route exact path='/razorpayPayments' element={<RazorpayPayments />} />

            </Route>

            <Route element={<PrivateRoute layout={DefaultLayout} allowedRoles={["USER"]} />} >

              <Route path="/book-appointment" element={<BookAppointmentRoute />}>

                <Route exact path="/book-appointment/step-1" element={<BookAppointment />} />

                <Route exact path="/book-appointment/step-2" element={<Details />} />

                <Route exact path="/book-appointment/step-3" element={<FinalBookAppointmentEditInfoPage />} />

              </Route>

              <Route exact path="/paymentsuccess" element={<PaymentSuccess />} />

              <Route exact path="/billingActivity/:subscriptionId" element={<BillingActivity />} />

            </Route>

            <Route element={<PrivateRoute layout={DefaultLayout} allowedRoles={["USER", "ADMIN"]} />}>

              <Route exact path="/track/:bookingId" element={<Tracking />} />

            </Route>

            {/* routes with sidebar and navbar */}
            <Route element={<PrivateRoute layout={DefaultLayout} allowedRoles={["USER", "ADMIN", "SUPER_ADMIN", "CAPTAIN", "STATE_ADMIN", "CITY_ADMIN",]} />}>

              <Route exact path="/" element={<Dashboard />} />

              <Route exact path="/accounts/:key" element={<Settings />} />

              <Route exact path="/navigation" element={<Navigation />} />

              <Route exact path="/search" element={<Search />} />

              <Route exact path="/bookings" element={<BookingsFortheDay />} />

              <Route exact path="/deleteAccount" element={<DeleteAccount />} />

            </Route>

            <Route element={<PrivateRoute layout={NavbarOnlyLayout} allowedRoles={["USER", "ADMIN", "SUPER_ADMIN", "CAPTAIN", "STATE_ADMIN", "CITY_ADMIN",]} />}>

              <Route exact path="/addAddress" element={<AddAddress />} />

            </Route>

            {/* routes with navbar */}
            <Route element={<PrivateRoute layout={NavbarOnlyLayout} allowedRoles={["USER"]} />}>

              <Route exact path="/invoice/print/:invoiceId" element={<Invoice />} />

            </Route>

            <Route element={<PrivateRouteForPublicRoutes />}>

              <Route exact path="/signup" element={<Signup />} />

              <Route exact path="/forgot-password" element={<ForgotPassword />} />

              <Route exact path="/forgotPassword/:id/:token" element={<ResetPassword />} />

              <Route exact path="/verify/:id/:token" element={<VerifyPage />} />

              <Route exact path="/re-verify/:reverifyToken/:id" element={<ReVerify />} />

            </Route>
            {/* public routes */}
            <Route exact path="/switchRole" element={<SwitchRole />} />

            <Route exact path="/login" element={<Login />} />

            <Route path="/auth/google/callback" element={<GoogleAuthCallback />} />

            <Route path="*" element={<NotFound status={"404"} continueTo={"/"} subTitle={"Sorry, the page you visited does not exist."} />} />

          </Routes>
        </Content>
      </Layout>
    </div>
  );
}

export { App };
