import axios from "axios";

const apiURL = process.env.REACT_APP_BACKEND_URL;

// get payments for super admin
const getPayments = (page, search, sort) => {
    const token = localStorage.getItem("authToken");
    const config = {
        headers: {
            Authorization: token,
            "Content-Type": "application/json",
        },
    };
    let url = `${apiURL}/payment/fetchAllPaymentDetails?page=${page}`;
    if (search) {
        url += `&search=${search}`;
    }
    if (sort) url += `&sortField=${sort}`;
    return axios.get(url, config);
};


const getRazorpayPayments = (count = 10, skip = 0) => {
    const token = localStorage.getItem("authToken");
    const config = {
        headers: {
            Authorization: token,
            "Content-Type": "application/json",
        },
    };
    let url = `${apiURL}/payment/getAllPayments?count=${count}&skip=${skip}`;
    return axios.get(url, config);
};

export const fetchPaymentsApi = {
    getPayments,
    getRazorpayPayments
};
