import React, { useEffect, useState } from "react";
import ServiceCard from "../Common/ServiceCard";
import { useDispatch, useSelector } from "react-redux";
import { bookAppointmentAction } from "./State/bookAppointmentActions";
import Spinner from "../Spinner/Spinner";
import { useNavigate } from "react-router-dom";
import { profileAction } from "../Common/State/actions";
import useCheckEffects from "./Components/useCheckEffects";
import useWarnings from "../Common/Components/useWarnings";

/**
 * @param - no params needed
 * @returns service card
 * @description - if it is a new book appointment, post req will be created in handleCurrentSubscribe func or services can be updated
 * handleCurrentSubscribe is called when the save button is clicked
 */

const BookAppointment = () => {
  const booking_id = localStorage.getItem("bookingID");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {success, error, contextHolder} = useWarnings();
  const { bookappointmentdetailsbyID } = useSelector(
    (str) => str.bookAppointmentReducer
  );
  const { parentBoardingDetails } = useSelector((str) => str.profileReducer);
  const {
    isLoading,
    isLoadingUserDetails,
    isLoadingParentBoardingCenter,
    isLoadingUpdatedServices,
  } = useSelector((str) => str.loadingReducer);
  const { userDetails } = useSelector((str) => str.navbarReducer);
  const [subscribeduserNewServices, setSubscribeduserNewServices] = useState(
    []
  );
  const { isLoadingBillingSub } = useSelector(
    (str) => str.additionalloadingReducer
  );

  // user clicks on save, this function will be called
  const handleCurrentSubscribe = (services) => {
    if (services?.length === 1 && services?.[0]?.key === "petPickupAndDrops") {
      return error("Only Pet Pickup and drops service is not allowed")
    }
    let convertedData = services.reduce((acc, item) => {
      acc[item.key] = {
        available: true,
        serviceType: item.serviceType,
      };
      return acc;
    }, {});

    convertedData.steps = 2;
    if (localStorage.getItem("newBookAppointment") === "true") {
      dispatch(
        profileAction.selectServicesEvent(
          convertedData,
          userDetails.subscription_id
        )
      );
    } else if (booking_id) {
      dispatch(
        bookAppointmentAction.updateSelectedServicesEvent(
          convertedData,
          booking_id
        )
      );
    } else {
      dispatch(profileAction.selectServicesEvent(convertedData));
    }
    localStorage.setItem("newBookAppointment", 'false');
    navigate("/book-appointment/step-2");
  };

  useEffect(() => {
    if (localStorage.getItem("newBookAppointment") === "true") {
      dispatch(bookAppointmentAction.bookappointmentByIDresetEvent());
      if (userDetails?.subscription_id) {
        // setSubscribeduserNewServices(
        //   plans.find((e) => e.planName === subscriptionType)
        // );
        setSubscribeduserNewServices(
          parentBoardingDetails?.subscriptionPlanServices
        );
      }
    } else if (booking_id) {
      dispatch(
        bookAppointmentAction.getBookAppointmentdetailsByIdEvent(booking_id)
      );
      localStorage.setItem("newBookAppointment", 'false');
    }
    // eslint-disable-next-line
  }, [parentBoardingDetails]);

  useCheckEffects("Book Appointment");

  if (
    isLoading ||
    isLoadingParentBoardingCenter ||
    isLoadingBillingSub ||
    isLoadingUserDetails ||
    isLoadingUpdatedServices
  ) {
    return <Spinner />;
  }

  return (
    <>
    {contextHolder}
      <ServiceCard
        parentBoardingDetails={
          parentBoardingDetails ? parentBoardingDetails : {}
        }
        handleCurrentSubscribe={handleCurrentSubscribe}
        servicesSelected={
          localStorage.getItem("newBookAppointment") === "true" &&
            userDetails?.subscription_id
            ? subscribeduserNewServices
            : bookappointmentdetailsbyID?.services
        }
      />
    </>
  );
};

export { BookAppointment };

// import React, { useEffect, useState } from "react";
// import ServiceCard from "../Common/ServiceCard";
// import { useDispatch, useSelector } from "react-redux";
// import { bookAppointmentAction } from "./State/bookAppointmentActions";
// import Spinner from "../Spinner/Spinner";
// import { useNavigate, useParams } from "react-router-dom";
// import { profileAction } from "../Common/State/actions";
// import { jwtDecode } from "jwt-decode";
// import { plans } from "../Common/Components/Plans";
// import { resetName, setName } from "../state/actions";

// const BookAppointment = () => {
//   const dispatch = useDispatch();
//   const { bookappointmentdetailsbyID } = useSelector((str) => str.bookAppointmentReducer);
//   const { parentBoardingDetails, selectedservices } = useSelector(str => str.profileReducer);
//   const { isLoadingBooking } = useSelector((str) => str.loadingReducer);
//   // let booking_id = localStorage.getItem("bookingID");
//   const navigate = useNavigate();
//   const { subscriptionType } = jwtDecode(localStorage.getItem("authToken"));
//   const [subscribeduserNewServices, setSubscribeduserNewServices] = useState([]);
//   const { type, bookingID } = useParams();
//   console.log(isLoadingBooking, "isLoadingBooking")

//   const handleCurrentSubscribe = (services) => {

//     let convertedData = services.reduce((acc, item) => {
//       acc[item.key] = {
//         available: true,
//         serviceType: item.serviceType,
//       };
//       return acc;
//     }, {});

//     convertedData.steps = 2;
//     if (type === "new" && bookingID === "id") {
//       dispatch(profileAction.selectServicesEvent(convertedData));
//       console.log("hs")
//       if (isLoadingBooking === "stop") {
//         console.log("hello5")
//         let id = selectedservices?.services?._id;
//         // navigate(`/book-appointment/old/${id}?step=2`);
//       }
//     } else if (bookingID !== "id" && type === "old") {
//       dispatch(
//         bookAppointmentAction.updateSelectedServicesEvent(
//           convertedData,
//           bookingID
//         )
//       );
//       // navigate(`/book-appointment/old/${bookingID}?step=2`);
//     }
//     else {
//       dispatch(profileAction.selectServicesEvent(convertedData));
//       let id = selectedservices?.services?._id;
//       // navigate(`/book-appointment/old/${id}?step=2`);
//     }
//   };

//   useEffect(() => {
//     dispatch(profileAction.parentBoardingDetailsEvent());
//   }, []);

//   useEffect(() => {
//     if (type === "new" && bookingID === "id") {
//       dispatch(bookAppointmentAction.bookappointmentByIDresetEvent());
//       if (subscriptionType) {
//         setSubscribeduserNewServices(plans.find(e => e.key === subscriptionType))
//       }
//     } else if (bookingID !== "id" && type === "old") {
//       dispatch(
//         bookAppointmentAction.getBookAppointmentdetailsByIdEvent(bookingID)
//       );
//     }
//   }, []);

//   useEffect(() => {
//     dispatch(setName("Book Appointment"));
//     return () => {
//       dispatch(resetName());
//     }
//   }, []);

//   // if (isLoadingBooking === "true") {
//   //   return <Spinner />;
//   // }

//   return (
//     <>
//       <ServiceCard
//         parentBoardingDetails={parentBoardingDetails ? parentBoardingDetails : {}}
//         handleCurrentSubscribe={handleCurrentSubscribe}
//         servicesSelected={localStorage.getItem("newBookAppointment") === "true" && subscriptionType ? subscribeduserNewServices.serviceDetails :
//           bookappointmentdetailsbyID?.servicesArray}
//       />
//     </>
//   );
// };

// export { BookAppointment };
