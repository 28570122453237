import { fetchPaymentsConstants } from "./action-types";

const initialState = {
  fetchPayments: false,
  fetchPaymentsErr: false,
  totalFetchPayments: 0,
  fetchRazorpayPayments: [],
  fetchRazorpayPaymentsErr: false,
};

const fetchPaymentsReducer = (state = initialState, action) => {
  switch (action.type) {
    case fetchPaymentsConstants.GET_ALL_PAYEMNTS_SUCCESS:
      return {
        ...state,
        fetchPayments:
          action.data.currentPage === 1
            ? action.data.data
            : [...state.fetchPayments, ...action.data.data],
        fetchPaymentsErr: false,
        totalFetchPayments: action.data.total,
      };
    case fetchPaymentsConstants.GET_ALL_PAYEMNTS_FAILURE:
      return {
        ...state,
        fetchPayments: false,
        fetchPaymentsErr: action.valuesError,
        totalFetchPayments: 0,
      };
    case fetchPaymentsConstants.GET_ALL_RAZORPAY_PAYEMNTS_SUCCESS:
      return {
        ...state,
        fetchRazorpayPayments:[...state.fetchRazorpayPayments, ...action.data.items],
        fetchRazorpayPaymentsErr: action.data.count!==0 ? false : 'Completed',
      };
    case fetchPaymentsConstants.GET_ALL_RAZORPAY_PAYEMNTS_FAILURE:
      return {
        ...state,
        fetchRazorpayPayments: [],
        fetchRazorpayPaymentsErr: action.valuesError,
      };
    default:
      return state;
  }
};
export { fetchPaymentsReducer };
