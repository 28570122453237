import React, { useEffect, useState } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { verifySignature } from "../../Common/Verify";
import Spinner from "../../Spinner/Spinner";
const PrivateRouteForPublicRoutes = () => {
  const token = localStorage.getItem("authToken");
  const [loading, setLoading] = useState(true);
  const [authentication, setAuthentication] = useState(false);

  useEffect(() => {
    const verifyfn = async () => {
      try {
        const res = await verifySignature(token);
        setAuthentication(res);
      } catch (error) {
        setAuthentication(false);
      } finally {
        setLoading(false); // Mark loading as false when the verification is complete
      }
    };
    if (token) {
      verifyfn();
    } else {
      setLoading(false); // No token, no need to verify
    }
  }, [token]);

  if (loading) {
    return <Spinner />; // Show a loading spinner until the verification is complete
  }
  // const authentication = verifySignature(token);

  if (!authentication) {
    return <Outlet />;
  }

  return <Navigate to="/" />;
};

export { PrivateRouteForPublicRoutes };
