import { startLoading, startLoadingRequestOtp, stopLoading, stopLoadingRequestOtp } from "../../state/actions";
import { signupApi } from "../utils/api";
import { signupinConstants } from "./action-types";

const forgotPasswordSuccess = (data) => {
  return {
    type: signupinConstants.FORGOT_PASSWORD_SUCCESS,
    forgotData: data,
  };
};

const forgotPasswordError = (err) => {
  return {
    type: signupinConstants.FORGOT_PASSWORD_FAILURE,
    forgotError: err,
  };
};

const forgotPasswordEvent = (data) => {
  return (dispatch) => {
    dispatch(startLoading());
    signupApi
      .forgotPassword(data)
      .then((response) => {
        if (
          (response.data.hasOwnProperty("statusCode") &&
            response.data.statusCode === 401) ||
          response.data.statusCode === 404
        ) {
          console.log(response, 'res')
          dispatch(forgotPasswordError(response.data.info.message));
          dispatch(stopLoading());
        } else {
          dispatch(forgotPasswordSuccess(response.data.info.message));
          dispatch(stopLoading());
        }
      })
      .catch((err) => {
        console.log(err, 'errrrr')
        dispatch(forgotPasswordError(err?.response?.data?.message));
        dispatch(stopLoading());
      });
  };
};
const changePasswordSuccess = (data) => {
  return {
    type: signupinConstants.CHANGE_PASSWORD_SUCCESS,
    changedData: data,
  };
};

const changePasswordError = (err) => {
  return {
    type: signupinConstants.CHANGE_PASSWORD_FAILURE,
    changedError: err,
  };
};

const changePasswordEvent = (data, id, token) => {
  return (dispatch) => {
    dispatch(startLoading());
    signupApi
      .changePassword(data, id, token)
      .then((response) => {
        if (
          (response.data.hasOwnProperty("statusCode") &&
            response.data.statusCode === 401) ||
          response.data.statusCode === 404
        ) {
          dispatch(changePasswordError(response.data.info.message));
          dispatch(stopLoading());
        } else {
          dispatch(changePasswordSuccess(response.data.info.message));
          dispatch(stopLoading());
        }
      })
      .catch((err) => {
        dispatch(changePasswordError(err.response.data.message));
        dispatch(stopLoading());
      });
  };
};

const verifyOtpAccountRestorationSuccess = (data) => {
  return {
    type: signupinConstants.VERIFY_OTP_SUCCESS_ACCOUNT_RESTORATION,
    data: data,
  };
};

const verifyOtpAccountRestorationErr = (err) => {
  return {
    type: signupinConstants.VERIFY_OTP_FAILURE_ACCOUNT_RESTORATION,
    valuesError: err,
  };
};

const verifyOtpAccountRestorationEvent = (id, token, data) => {
  return (dispatch) => {
    dispatch(startLoading());
    signupApi
      .verifyOtpForRestorationAccount(id, token, data)
      .then((response) => {
        dispatch(verifyOtpAccountRestorationSuccess(response.data.info.message));
        dispatch(stopLoading());
      })
      .catch((err) => {
        console.log(err, 'err');
        dispatch(verifyOtpAccountRestorationErr(err.response.data.message));
        dispatch(stopLoading());
      });
  };
};

const requestOtpAccountRestorationSuccess = (data) => {
  return {
    type: signupinConstants.REQUEST_OTP_SUCCESS_ACCOUNT_RESTORATION,
    data: data,
  };
};

const requestOtpAccountRestorationErr = (err) => {
  return {
    type: signupinConstants.REQUEST_OTP_FAILURE_ACCOUNT_RESTORATION,
    valuesError: err,
  };
};

const requestOtpAccountRestorationEvent = (id) => {
  return (dispatch) => {
    dispatch(startLoadingRequestOtp());
    signupApi
      .requestOtpForRestorationAccount(id)
      .then((response) => {
        dispatch(requestOtpAccountRestorationSuccess(response.data.info.message));
        dispatch(stopLoadingRequestOtp());
      })
      .catch((err) => {
        dispatch(requestOtpAccountRestorationErr(err.response.data.message));
        dispatch(stopLoadingRequestOtp());
      });
  };
};

const clearaccountRestorationOTPdata = () => {
  return {
    type: signupinConstants.CLEAR_ACCOUNT_RESTORATION_OTP_DATA
  }
}


export const forgotAction = {
  forgotPasswordEvent,
  changePasswordEvent,
  requestOtpAccountRestorationEvent,
  verifyOtpAccountRestorationEvent,
  clearaccountRestorationOTPdata
};
