import React, { useEffect, useMemo, useState } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useSelector } from "react-redux";
import { verifySignature } from "../../Common/Verify";
import Spinner from "../../Spinner/Spinner";
import { NotFound } from "../../Common/Components/NotFound";
import DecodeToken from "../../Common/Components/DecodeToken";

const PrivateRoute = ({ layout: LayoutComponent, allowedRoles }) => {
  const token = localStorage.getItem("authToken");
  const [authentication, setAuthentication] = useState(false);
  const [loading, setLoading] = useState(true);
  console.log(authentication, 'authentication')

  useEffect(() => {
    const verifyfn = async () => {
      try {
        const res = await verifySignature(token);
        setAuthentication(res);
      } catch (error) {
        setAuthentication(false);
        // localStorage.removeItem('authToken');
        // localStorage.removeItem('newBookAppointment');
        // localStorage.removeItem("bookingID");
      } finally {
        setLoading(false); // Mark loading as false when the verification is complete
      }
    };
    if (token) {
      verifyfn();
    } else {
      setLoading(false); // No token, no need to verify
    }
  }, [token]);

  if (loading) {
    return <Spinner />; // Show a loading spinner until the verification is complete
  }

  if (!authentication) {
    return <Navigate to="/login" />;
  }
  let res = DecodeToken(token);
  console.log(res, "res");

  if (!res) {
    return <Navigate to="/login" />;
  }
  // Check if the user's role is in the allowedRoles array
  if (allowedRoles.length > 0 && !allowedRoles.includes(DecodeToken(token))) {
    return (
      <NotFound
        status={"403"}
        title={"403"}
        subTitle={"Sorry, you are not authorized to access this page"}
        continueTo={"/"}
      />
    ); // Redirect to a 'Not Authorized' page
  }

  return (
    <LayoutComponent>
      <Outlet /> {/* Renders nested routes */}
    </LayoutComponent>
  );
};

export { PrivateRoute };

// import React, { useEffect, useState } from "react";
// import { Navigate, Outlet } from "react-router-dom";
// import { verifySignature } from "../../Common/Verify";
// import Spinner from "../../Spinner/Spinner";
// import { NotFound } from "../../Common/Components/NotFound";
// import DecodeToken from "../../Common/Components/DecodeToken";

// const PrivateRoute = ({ layout: LayoutComponent, allowedRoles }) => {
//   const token = localStorage.getItem("authToken");
//   const [authentication, setAuthentication] = useState(null); // null: loading, true: authenticated, false: not authenticated
//   console.log(authentication, "authentication");

//   useEffect(() => {
//     const verifyAuthToken = async () => {
//       try {
//         const isValid = await verifySignature(token);
//         console.log(isValid, "isValid");
//         setAuthentication(isValid);
//       } catch (error) {
//         console.error("Token verification failed:", error);
//         setAuthentication(false);
//       }
//     };
//     if (token) verifyAuthToken();
//   }, [token]);

//   // Redirect to login if the token is invalid
//   if (!authentication) {
//     return <Navigate to="/login" />;
//   }

//   // Check if the user's role is in the allowedRoles array
//   const userRole = DecodeToken(token); // Assuming DecodeToken extracts the role
//   if (allowedRoles.length > 0 && !allowedRoles.includes(userRole)) {
//     return (
//       <NotFound
//         status={"403"}
//         title={"403"}
//         subTitle={"Sorry, you are not authorized to access this page"}
//         continueTo={"/"}
//       />
//     ); // Redirect to a 'Not Authorized' page
//   }

//   return (
//     <LayoutComponent>
//       <Outlet /> {/* Renders nested routes */}
//     </LayoutComponent>
//   );
// };

// export { PrivateRoute };
