
import { Button, Card, Form, Image, Input, Typography, theme } from "antd";
import React, { useEffect, useRef, useState } from "react";
import styles from "./Styles/login.module.css";
import { Link, useNavigate } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import { LeftOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { forgotAction } from "./State/forgotactions";
import useShowMessage from "./Components/useShowMessage";
import ReCAPTCHA from "react-google-recaptcha";
import { verifySignature } from "../Common/Verify";
import Spinner from "../Spinner/Spinner";
const { Text } = Typography;

/**
 * ForgotPassword component renders the forgot password page.
 *
 * @returns {React.ReactElement} A form where users can input their email address to reset their password.
 *
 * @description
 * - This component includes a form for users to submit their email address for password recovery.
 * - After the form is submitted, the input fields are reset, and an action is dispatched to handle the password recovery process.
 * - Displays a loading spinner when the request is being processed.
 * - Includes a link to navigate back to the login page.
 */

const ForgotPassword = () => {
  const isTab = useMediaQuery({ maxWidth: 576 });
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const { isLoading } = useSelector((str) => str.loadingReducer);
  const [authentication, setAuthentication] = useState(false);
  const [loading, setLoading] = useState(true);
  const captchaRef = useRef();
  const navigate = useNavigate()

  const onFinish = (formvalues) => {
    dispatch(forgotAction.forgotPasswordEvent(formvalues));
    // form.resetFields();
    // await captchaRef.current.reset();
  };

  const { useToken } = theme;
  const { token } = useToken();

  const { contextHolder } = useShowMessage(form);

  let authToken = localStorage.getItem("authToken");

  useEffect(() => {
    const verifyfn = async () => {
      try {
        const res = await verifySignature(token);
        setAuthentication(res);
      } catch (error) {
        setAuthentication(false);
      } finally {
        setLoading(false); // Mark loading as false when the verification is complete
      }
    };
    if (token) {
      verifyfn();
    } else {
      setLoading(false); // No token, no need to verify
    }
  }, [token]);

  // const authentication = verifySignature(authToken);

  useEffect(() => {
    if (authentication) {
      // if (role === "USER") navigate("/");
      // else if (role === "SUPER_ADMIN" || role === "ADMIN" || role === "CAPTAIN")
      navigate("/");
    }
    // eslint-disable-next-line
  }, [authentication]);

  if (loading) return <Spinner />;

  return (
    <div className={styles.loginpage} style={{ flexDirection: "column", backgroundImage: `url("/Assets/LoginAssets/loginscreen.png")` }}>
      {contextHolder}
      <div
        style={{ display: isTab ? "none" : "", width: isTab ? "" : "500px" }}
      >
        <Image src={'/Assets/LoginAssets/logindogs.png'} alt="dogs" preview={false} />
      </div>
      <Card
        hoverable
        style={{ width: isTab ? "100%" : "500px", height: isTab ? "100%" : "" }}
      >
        <Form form={form} layout="vertical" onFinish={onFinish}>
          <Form.Item
            required
            name={"email"}
            initialValue={localStorage.getItem("userName")}
            rules={[
              {
                type: "email",
                message: "Please enter valid email address",
              },
              {
                required: true,
                message: "Please input your Email Address!",
              },
            ]}
          >
            <Input
              placeholder="Enter Email address"
              className={styles.formInput}
            />
          </Form.Item>
          <Form.Item
            required
            name={"recaptcha"}
            rules={[
              {
                required: true,
                message: "Please verify",
              },
            ]}
          >
            <ReCAPTCHA
              sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
              ref={captchaRef}
              style={{ width: '100%', justifyContent: 'center', display: 'flex' }}
            />
          </Form.Item>

          <Form.Item>
            <Button
              htmlType="submit"
              style={{
                width: "100%",
                height: "45px",
                backgroundColor: token.appbluecolor,
                color: token.White,
                fontSize: "18px",
              }}
              loading={isLoading}
            >
              Send
            </Button>
          </Form.Item>
        </Form>
        <Text style={{ display: "flex", justifyContent: "center" }}>
          <LeftOutlined style={{ color: token.Black }} />
          <Link to={"/login"}>&nbsp;Go to Login</Link>
        </Text>
      </Card>
    </div>
  );
};

export default ForgotPassword;
