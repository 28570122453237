import { CaretRightFilled } from "@ant-design/icons";
import { Button, Card, Form, Row, Typography } from "antd";
import React, { useEffect, useState } from "react";
import useWarnings from "./useWarnings";
import { profileapi } from "../utils/profileapi";
import VerifyAccount from "./VerifyAccount";
import { useDispatch, useSelector } from "react-redux";
import { signupAction } from "../../Signup/State/actions";
import Spinner from "../../Spinner/Spinner";
import { addressApi } from "../utils/api";
import FinalPageToDeleteAccount from "./FinalPageToDeleteAccount";
import StepsFormToDelete from "./StepsFormToDelete";
import { navbarAction } from "../../Navbar/State/actions";
import { useNavigate } from "react-router-dom";
const { Text, Title } = Typography;

const DeleteAccount = () => {
    const [show, setShow] = useState({
        form: false,
        final: false,
        verified: false,
    });
    const [form] = Form.useForm();
    const [current, setCurrent] = useState(0);
    const [loading, setLoading] = useState(false);
    const { error, success, contextHolder } = useWarnings();
    const [formValues, setFormValues] = useState({});
    const dispatch = useDispatch();
    const { userDetails } = useSelector((str) => str.navbarReducer);
    const { requestOtp, requestOtpError } = useSelector(
        (str) => str.signupReducer
    );
    const { isLoadingRequestOtp } = useSelector((str) => str.loadingReducer);
    const navigate = useNavigate();

    useEffect(() => {
        if (requestOtpError) error(requestOtpError);

        if (requestOtp) {
            success(requestOtp);
            setShow((prev) => ({ ...prev, form: false, final: true }));
        }

        dispatch(signupAction.clearOTPdata());
    }, [requestOtp, requestOtpError]);

    const onFinish = async (values) => {
        try {
            setLoading(true);
            const response = await addressApi.verifyotpTodelete({
                otp: values?.otp,
                role: userDetails?.currentRole,
            });
            if (response?.data?.info) {
                success("OTP Verified");
                setLoading(false);
                setShow({ form: false, final: false, verified: true });
            } else {
                error("Something went wrong");
                setLoading(false);
            }
        } catch (err) {
            setLoading(false);
            error(err?.response?.data?.message);
        }
    };

    const handleNext = async () => {
        let value = await form.getFieldsValue();
        try {
            await form.validateFields();
            setFormValues((prev) => ({ ...prev, ...value }));
            if (current !== 2) setCurrent(current + 1);
            if (current === 2) {
                dispatch(
                    signupAction.requestOTPEvent({
                        email: userDetails?.email,
                        role: userDetails?.currentRole,
                    })
                );
            }
        } catch (err) {
            console.log("Validation failed:", err);
        }
    };

    const handleDelete = async () => {
        setLoading(true);
        try {
            let response;
            if (formValues.deleteAccountReason !== "other") {
                response = await profileapi.accountDelete({
                    isDeleted: {
                        status: true,
                        reason: formValues,
                    },
                });
            } else {
                let { deleteAccountReason, other, ...data } = formValues;
                response = await profileapi.accountDelete({
                    isDeleted: {
                        status: true,
                        reason: {
                            ...data,
                            deleteAccountReason: other,
                        },
                    },
                });
            }
            if (response?.data?.info) {
                success("Account deleted successfully");
                dispatch(navbarAction.logoutEvent());
                dispatch(navbarAction.userDetailsReset());
                navigate("/login");
            } else {
                error("Something went wrong");
            }
            setLoading(false);
        } catch (err) {
            error(err?.response?.data?.message);
            setLoading(false);
        }
    };

    if (isLoadingRequestOtp) return <Spinner />;

    return (
        <>
            {contextHolder}
            {show?.verified ? (
                <VerifyAccount handleDelete={handleDelete} loading={loading} />
            ) : show.final ? (
                <FinalPageToDeleteAccount
                    onFinish={onFinish}
                    loading={loading}
                    setCurrent={setCurrent}
                    setShow={setShow}
                />
            ) : show.form ? (
                <StepsFormToDelete
                    form={form}
                    current={current}
                    handleNext={handleNext}
                    setCurrent={setCurrent}
                />
            ) : (
                <Row
                    justify={"center"}
                    style={{ alignItems: "center", height: "80vh" }}
                >
                    <Card hoverable style={{ maxWidth: "400px" }}>
                        <Title style={{ fontSize: "1.2em", textAlign: "center" }}>
                            Are you sure you want to delete your account?
                        </Title>
                        <Text>
                            Deleting your account will remove all your data permanently. You
                            won't be able to recover it.
                        </Text>
                        <Row style={{ justifyContent: "end", marginTop: "1.2em" }}>
                            <Button
                                danger
                                size="small"
                                iconPosition="end"
                                onClick={() =>
                                    setShow((prev) => ({ ...prev, form: true, final: false }))
                                }
                                icon={<CaretRightFilled />}
                            >
                                Continue{" "}
                            </Button>
                        </Row>
                    </Card>
                </Row>
            )}
        </>
    );
};

export default DeleteAccount;
