import React, { useEffect, useState } from "react";
import {
  Button,
  Drawer,
  Image,
  Layout,
  Menu,
  message,
  notification,
  Row,
  theme,
  Typography,
} from "antd";
import { CloseOutlined } from "@ant-design/icons";
import { useMediaQuery } from "react-responsive";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import LargeScreenNavbar from "./Components/LargeScreenNavbar";
import { navbarAction } from "./State/actions";
import useGenerateToken, { messaging } from "../Notifications/firebase";
import { onMessage } from "firebase/messaging";
import { signupAction } from "../Signup/State/actions";
import { useSocket } from "../context/SocketContext";
import ProfileItems from "./Components/ProfileItems";
import { appAction } from "../App/state/actions";
import { helpAction } from "../Help/State/actions";
import { useRoute } from "../context/RouteContext";
import NotificationDrawer from "./Components/NotificationDrawer";
import HelpDrawer from "./Components/HelpDrawer";
import CommonModal from "../CommonModal/CommonModal";
import OptionsComponent from "../Common/Components/MenuData";
import HeyhomeLogo from "../Assets/Header/logo 16-01 1.png";
import HeyhomeText from "../Assets/Header/Hey home.png";
const { Header } = Layout;
const { Text } = Typography;

/**
 * A responsive navigation bar component that handles user interactions and notifications.
 *
 * This component adjusts its layout based on the screen size, displaying a large screen
 * navbar or a small screen navbar accordingly. It also integrates with Firebase for push notifications,
 * handles user logout, role switching, and listens to socket events for real-time updates.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {string} props.finalAddress - The final address to display in the navbar.
 *
 * @returns {JSX.Element} The rendered navbar, either large or small screen version based on the viewport size.
 */

const Navbar = ({ finalAddress }) => {
  const { updatedboardingcenter } = useSelector((str) => str.addressReducer);
  const { userDetails } = useSelector((str) => str.navbarReducer);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { currentSocket } = useSocket();
  const [api, contextHolder] = notification.useNotification();
  const [messageApi, holder] = message.useMessage();
  const { routeKey } = useRoute();

  const [visible, setVisible] = useState({
    modalVisible: false,
    reason: "",
  });
  const isMediumScreen = useMediaQuery({ maxWidth: 768 });
  const [visiblemenu, setVisibleMenu] = useState(false);
  const { useToken } = theme;
  const { token } = useToken();
  const { selectedKey, setSelectedKey } = useRoute();

  useGenerateToken();

  useEffect(() => {
    onMessage(messaging, (payload) => {
      api.success({
        message: payload.notification.title,
        description: payload.notification.body,
        duration: 0,
      });
      dispatch(navbarAction.getNotificationCountEvent());
    });
    // eslint-disable-next-line
  }, []);

  const onClickEvent = () => {
    dispatch(navbarAction.logoutEvent());
    dispatch(navbarAction.userDetailsReset());
    navigate("/login");
  };

  const onSwitchRole = (role) => {
    dispatch(
      signupAction.requestOTPEvent({ email: userDetails?.email, role: role })
    );
    navigate("/switchRole", { state: role });
  };

  useEffect(() => {
    if (updatedboardingcenter) dispatch(navbarAction.getUserDetails());
    // eslint-disable-next-line
  }, [updatedboardingcenter]);

  useEffect(() => {
    if (currentSocket) {
      currentSocket?.on("madePayment", (message) => {
        alert(message);
      });
      currentSocket?.on("activity", (message) => {
        localStorage.removeItem("authToken");
        localStorage.removeItem("fcmToken");
        localStorage.removeItem("newBookAppointment");
        localStorage.removeItem("bookingID");
        setVisible({
          modalVisible: true,
          reason: "Session Expired.Please login again.",
        });
      });
    }
  }, [currentSocket]);

  useEffect(() => {
    dispatch(appAction.mapTokenEvent());
    dispatch(navbarAction.getNotificationCountEvent());
  }, []);

  const [open, setOpen] = useState({
    notification: false,
    help: false,
  });

  const showDrawer = () => {
    setOpen((prev) => ({ ...prev, notification: true }));
  };

  const onClose = () => {
    setOpen((prev) => ({ ...prev, notification: false }));
    dispatch(navbarAction.clearNotifications());
  };

  const showDrawerHelp = () => {
    // setOpenHelp(true);
    setOpen((prev) => ({ ...prev, help: true }));
    dispatch(helpAction.getQuestionByKeyEvent(routeKey, "Web"));
  };

  const onCloseHelp = () => {
    setOpen((prev) => ({ ...prev, help: false }));
  };

  const currentRole = userDetails?.currentRole;

  const profileItems = ProfileItems({
    userDetails,
    currentRole,
    onSwitchRole,
    onClickEvent,
  });

  const onClick = (e) => {
    setSelectedKey(e?.key);
    if(e.key === 'CRUD_BOOK_APPOINTMENT') 
    localStorage.setItem("newBookAppointment", true);
    // localStorage.removeItem("bookingID");
    navigate(`/${e?.item?.props?.path}`);
  };

  useEffect(() => {
    if (!isMediumScreen) setVisibleMenu(false);
  }, [isMediumScreen]);

  return (
    <>
      {contextHolder}
      {holder}
      <Header
        style={{
          padding: "0px 24px",
          position: "sticky",
          top: 0,
          zIndex: 100,
        }}
      >
        <LargeScreenNavbar
          finalAddress={finalAddress}
          profileItems={profileItems}
          showDrawer={showDrawer}
          showDrawerHelp={showDrawerHelp}
          setVisibleMenu={setVisibleMenu}
        />
        <Drawer
          closable
          styles={{
            header: { background: token.Secondary },
            body: { background: token.Secondary, padding: 0 },
          }}
          placement="left"
          title={""}
          width={300}
          closeIcon={<CloseOutlined style={{ color: "white" }} />}
          onClose={() => setVisibleMenu(false)}
          open={visiblemenu}
        >
          <Row
            style={{
              marginTop: "1.2em",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Image
              src={HeyhomeLogo}
              height={60}
              preview={false}
              style={{ objectFit: "cover" }}
            />
            <Image
              src={HeyhomeText}
              preview={false}
              style={{ objectFit: "cover" }}
            />
          </Row>
          <Menu
            onSelect={onClick}
            selectedKeys={[selectedKey]}
            style={{
              background: token.Secondary,
              height: "100vh",
              overflow: "scroll",
            }}
            theme="light"
            mode="inline"
            items={OptionsComponent()}
          />
        </Drawer>
        <CommonModal
          maskClosable={false}
          keyboard={false}
          isModalOpen={visible.modalVisible}
          body={() => (
            <>
              <Text style={{ display: "block" }}>{visible?.reason}</Text>
              <Button onClick={onClickEvent} type="primary">
                LOGIN
              </Button>
            </>
          )}
        />
        {open.notification && (
          <NotificationDrawer onClose={onClose} open={open.notification} />
        )}
        {open.help && <HelpDrawer onClose={onCloseHelp} open={open.help} />}
      </Header>
    </>
  );
};

export { Navbar };
