import { Card, Divider, Form, Image, Typography } from "antd";
import React, { useEffect, useRef, useState } from "react";
import styles from "./Styles/signup.module.css";
import "react-phone-input-2/lib/style.css";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import googlesvg from "../Assets/Login/googlesvg.svg";
import { useDispatch } from "react-redux";
import { signupAction } from "./State/actions";
import SignupForm from "./Components/SignupForm";
import useShowMessage from "./Components/useShowMessage";
import googleplaystore from '../Assets/googleplay/getitonGooglePay.png'
import { verifySignature } from "../Common/Verify";
import Spinner from "../Spinner/Spinner";
const { Text } = Typography;

/**
 * Signup component provides a user interface for creating a new account.
 * It includes options to signup via email, along with a form for entering user details.

 * @returns {JSX.Element} The rendered Signup component.
*/

const Signup = () => {
  const isTab = useMediaQuery({ maxWidth: 576 });
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const captchaRef = useRef();
  const navigate = useNavigate();
  
  const [authentication, setAuthentication] = useState(false);
  const [loading, setLoading] = useState(true);

  const handleSignup = (formvalues) => {
    const { cpassword, phoneNumber, referredBy, ...formData } = formvalues;

    let signupdata = { ...formData };

    if (phoneNumber && phoneNumber.trim() !== "") {
      const countryCode = phoneNumber.substring(0, phoneNumber.length - 10);
      const formattedPhoneNumber = phoneNumber.substring(
        phoneNumber.length - 10
      );
      signupdata = {
        ...signupdata,
        countryCode,
        phoneNumber: formattedPhoneNumber,
      };
    }
    if (referredBy) {
      dispatch(signupAction.signupEvent({ ...signupdata, referredBy }));
    } else dispatch(signupAction.signupEvent(signupdata));
    // captchaRef.current.reset();
    // form.resetFields();
  };

  const { contextHolder } = useShowMessage(form);

  let token = localStorage.getItem("authToken");


  console.log(authentication, "authentication");

  useEffect(() => {
    const verifyfn = async () => {
      try {
        const res = await verifySignature(token);
        setAuthentication(res);
      } catch (error) {
        setAuthentication(false);
      } finally {
        setLoading(false); // Mark loading as false when the verification is complete
      }
    };
    if (token) {
      verifyfn();
    } else {
      setLoading(false); // No token, no need to verify
    }
  }, [token]);

  // const authentication = verifySignature(token);

  useEffect(() => {
    if (authentication) {
      // if (role === "USER") navigate("/");
      // else if (role === "SUPER_ADMIN" || role === "ADMIN" || role === "CAPTAIN")
      navigate("/");
    }
    // eslint-disable-next-line
  }, [authentication]);

  if(loading) return <Spinner />;

  return (
    <>
      {contextHolder}
      <div
        className={styles.signuppage}
        style={{
          flexDirection: "column",
          backgroundImage: `url("/Assets/LoginAssets/loginscreen.png")`,
        }}
      >
        {/* dogs image */}
        {/* <div
          style={{ display: isTab ? "none" : "", width: isTab ? "" : "500px" }}
        >
          <Image
            src={"/Assets/LoginAssets/logindogs.png"}
            alt="dogs"
            preview={false}
          />
        </div> */}

        {/* sign up form  */}
        <Card
          hoverable
          style={{
            width: isTab ? "100%" : "500px",
            height: isTab ? "100%" : "",
          }}
        >
          <Text
            style={{
              fontSize: "1.5em",
              display: "block",
              fontWeight: 500,
              marginBottom: "20px",
            }}
          >
            {" "}
            👋 Create an account !
          </Text>
          <SignupForm
            handleSignup={handleSignup}
            form={form}
            captchaRef={captchaRef}
          />
          <Text style={{ display: "flex", justifyContent: "center" }}>
            {" "}
            Already have an account ? <Link to={"/login"}>&nbsp;Login Now</Link>
          </Text>
          <div style={{ textAlign: 'center' }}>
            <Text style={{ display: "flex", justifyContent: "center" }}>
              Get the app.
            </Text>
            <Image src={googleplaystore} width={150} preview={false} />
          </div>
        </Card>
      </div>
    </>
  );
};

export default Signup;
