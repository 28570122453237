import axios from "axios";
import { GlobalEncrypt } from "../GlobalEncrypt";
const apiURL = process.env.REACT_APP_BACKEND_URL;

// add address
const addAddress = (data) => {
  const encryptedData = GlobalEncrypt(data);
  // console.log(encryptedData, "encrypted data");
  const token = localStorage.getItem("authToken");
  const config = {
    headers: {
      Authorization: token,
      "Content-Type": "application/json",
    },
  };
  let res = axios.post(`${apiURL}/addAddress`, encryptedData, config);
  return res;
};

// get all addresses
const getAllAddresses = () => {
  const token = localStorage.getItem("authToken");
  const config = {
    headers: {
      Authorization: token,
      "Content-Type": "application/json",
    },
  };
  return axios.get(`${apiURL}/getAllUserAddress`, config);
};

// update address
const updateAddress = (data, id) => {
  const token = localStorage.getItem("authToken");
  const config = {
    headers: {
      Authorization: token,
      "Content-Type": "application/json",
    },
  };
  return axios.put(`${apiURL}/updateAddress/${id}`, data, config);
};

// get boarding center by address id
const getBoardingCenterbyId = (id) => {
  const token = localStorage.getItem("authToken");
  const config = {
    headers: {
      Authorization: token,
      "Content-Type": "application/json",
    },
  };
  return axios.get(`${apiURL}/getBoardingCenterLocation/${id}`, config);
};

//update boarding center
const updateBoardingCenter = (data) => {
  const token = localStorage.getItem("authToken");
  const config = {
    headers: {
      Authorization: token,
      "Content-Type": "application/json",
    },
  };
  return axios.put(`${apiURL}/bookAppointment/selectBoardCenter`, data, config);
};

// get coordinates from source to destination
// const getCoordinates = async (souceLat, sourcelng, destlat, destLng, token) => {
//   return await axios.get(`https://apis.mappls.com/advancedmaps/v1/${token}/route_adv/driving/${sourcelng},${souceLat};${destLng},${destlat}?geometries=geojson`,
//     {
//       headers: null
//     }
//   )
// }
// get coordinates from source to destination
const getCoordinates = async (
  data
  // souceLat,
  // sourcelng,
  // destlat,
  // destLng,
  // desteLoc,
  // sourceLoc
) => {
  console.log(data, "data");
  const { souceLat, sourcelng, destlat, destLng, desteLoc, sourceLoc } = data;
  let url;
  if (desteLoc && sourceLoc) {
    url = `${apiURL}/getCoordinates?desteLoc=${desteLoc}&sourceLoc=${sourceLoc}`;
  } else if (desteLoc && sourcelng && souceLat) {
    url = `${apiURL}/getCoordinates?desteLoc=${desteLoc}&sourcelng=${sourcelng}&sourceLat=${souceLat}`;
  } else if (sourceLoc && destLng && destlat) {
    url = `${apiURL}/getCoordinates?sourceLoc=${sourceLoc}&destLng=${destLng}&destlat=${destlat}`;
  } else {
    url = `${apiURL}/getCoordinates?sourcelng=${sourcelng}&sourceLat=${souceLat}&destLng=${destLng}&destlat=${destlat}`;
  }
  return await axios.get(url, {
    headers: null,
  });
};

const verifyotpTodelete = (data) => {
  const token = localStorage.getItem("authToken");
  const config = {
    headers: {
      Authorization: token,
      "Content-Type": "application/json",
    },
  };
  return axios.post(`${apiURL}/verifyOTPToDeleteAccount`, data, config);
};

export const addressApi = {
  addAddress,
  getAllAddresses,
  updateAddress,
  getBoardingCenterbyId,
  updateBoardingCenter,
  getCoordinates,
  verifyotpTodelete
};
