import { CaretLeftFilled, CaretRightFilled } from "@ant-design/icons";
import { Button, Card, Checkbox, Col, Form, Input, Row, Select, Steps } from "antd";
import React from "react";
const { TextArea } = Input;

const StepsFormToDelete = ({ current, setCurrent, handleNext, form }) => {

  const deleteAccountOptions = [
    { value: "no_longer_needed", label: "No longer needed" },
    { value: "privacy_concerns", label: "Privacy concerns" },
    { value: "not_satisfied", label: "Not satisfied with the services" },
    { value: "other", label: "Other" },
  ];

  const steps = [
    {
      key: "1",
      // title: "1",
      content: (
        <>
          <Form.Item
            label="Why are you deleting your account?"
            name={"deleteAccountReason"}
            rules={[{ required: true }]}
          >
            <Select options={deleteAccountOptions} />
          </Form.Item>
          <Form.Item
            shouldUpdate={(prev, current) =>
              prev.deleteAccountReason !== current.deleteAccountReason
            }
          >
            {({ getFieldValue }) => {
              const reason = getFieldValue("deleteAccountReason");
              return reason === "other" ? (
                <Form.Item
                  name="other"
                  label="Please specify the reason"
                  rules={[
                    { required: true, message: "Please provide a reason." },
                  ]}
                >
                  <Input />
                </Form.Item>
              ) : null;
            }}
          </Form.Item>
        </>
      ),
    },
    {
      key: "2",
      // title: "2",
      content: (
        <Form.Item
          name="deleteAccountPermanently"
          valuePropName="checked"
          rules={[
            {
              validator: (_, value) =>
                value ? Promise.resolve() : Promise.reject("You must agree."),
            },
          ]}
        >
          <Checkbox>
            Do you understand that deleting your account will permanently remove
            your data and cannot be undone?
          </Checkbox>
        </Form.Item>
      ),
    },
    {
      key: "3",
      // title: "3",
      content: (
        <Form.Item
          rules={[{ required: true }]}
          name={"feedback"}
          label="Please provide feedback about your experience"
        >
          <TextArea rows={3} />
        </Form.Item>
      ),
    },
  ];

  return (
    <Row justify={"center"} style={{ alignItems: "center", height: "80vh" }}>
      <Col xs={22} sm={22} md={16} lg={12} xl={10}>
        <Card hoverable>
          <Steps
            responsive={false}
            size="small"
            current={current}
            items={steps}
          />
          <Form style={{ marginTop: "1.2em" }} form={form} layout="vertical">
            {steps[current].content}
          </Form>
          <Row style={{ justifyContent: "space-between" }}>
            <Button
              danger
              size="small"
              icon={<CaretLeftFilled />}
              disabled={current === 0}
              onClick={() => setCurrent(current - 1)}
            >
              Previous
            </Button>
            <Button
              htmlType="submit"
              size="small"
              icon={<CaretRightFilled />}
              iconPosition="end"
              onClick={handleNext}
            >
              Next
            </Button>
          </Row>
        </Card>
      </Col>
    </Row>
  );
};

export default StepsFormToDelete;
