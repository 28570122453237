import { jwtDecode } from "jwt-decode";

const DecodeToken = (token) => {
  if (!token) return false;
  let decodedRole = jwtDecode(token);
  if (!decodedRole.currentRole) {
    return false;
  }
  return decodedRole.currentRole;
};

export default DecodeToken;
