import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchPaymentActions } from "../State/actions";
import dayjs from "dayjs";
import { Table, Typography } from "antd";
import { CaretDownFilled, CaretRightFilled } from "@ant-design/icons";
import { useRoute } from "../../context/RouteContext";
const { Text } = Typography;

const RazorpayPayments = () => {
  const dispatch = useDispatch();
  const [skip, setSkip] = useState(0);
  const { setSelectedKey } = useRoute();

  const { fetchRazorpayPayments, fetchRazorpayPaymentsErr } = useSelector(
    (str) => str.fetchPaymentsReducer
  );
  const { isLoadingPaymentLinks } = useSelector(
    (str) => str.additionalloadingReducer
  );

  useEffect(() => {
    dispatch(fetchPaymentActions.getRazorpayPaymentsEvent(10, 0));
    setSelectedKey("FETCH_ALL_BOOKINGS_PAYMENTS");
  }, []);

  const columns = [
    {
      title: "Created On",
      key: "created_at",
      render: (_, { created_at }) => (
        <Text>{dayjs(created_at).format("MMM D, YYYY h:mm A")}</Text>
      ),
    },
    {
        title: "Payment Id",
        key: "id",
        dataIndex: "id"
      },
    {
      title: "Amount",
      key: "amount",
      render: (_, { amount, currency }) => (
        <Text>
          {currency} {amount / 100}
        </Text>
      ),
    },
    {
      title: "Status",
      key: "status",
      dataIndex: "status",
    },
    {
      title: "Order ID",
      key: "order_id",
      dataIndex: "order_id",
      fixed: "left",
    },
    {
      title: "Invoice ID",
      key: "invoice_id",
      dataIndex: "invoice_id",
      fixed: "left",
    },
    {
      title: "Payment Method",
      key: "method",
      dataIndex: "method",
    },
    {
      title: "Amount Refunded",
      key: "amount_refunded",
      dataIndex: "amount_refunded",
    },
    {
      title: "Refund Status",
      key: "refund_status",
      dataIndex: "refund_status",
    },
    {
      title: "Captured",
      key: "captured",
      render: (_, { captured }) => <Text>{captured ? "Yes" : "No"}</Text>,
    },
    {
      title: "Contact info",
      key: "contactInfo",
      width: "20%",
      render: ({ email, contact, notes }) => (
        <>
          <Text style={{ display: "block" }}>Email:{email}</Text>
          <Text style={{ display: "block" }}>Contact:{contact}</Text>
          <Text style={{ display: "block" }}>
            {Object.entries(notes)?.map(([key, value]) => {
              return (
                <Text style={{ display: "block" }}>
                  {key}: {value}
                </Text>
              );
            })}
          </Text>
        </>
      ),
    },
    {
      title: "Data",
      key: "data",
      render: ({ acquirer_data }) =>
        Object.entries(acquirer_data)?.map(([key, value]) => (
          <Text style={{ display: "block" }}>
            {key}: {value}
          </Text>
        )),
    },
  ];

  const loadMore = (skip) => {
    if (!isLoadingPaymentLinks && fetchRazorpayPaymentsErr !== "Completed")
      dispatch(fetchPaymentActions.getRazorpayPaymentsEvent(10, skip));
  };

  return (
    <Table
      pagination={false}
      bordered
      size="small"
      dataSource={fetchRazorpayPayments}
      columns={columns}
      loading={isLoadingPaymentLinks}
      rowKey={(record) => record.id}
      onScroll={(e) => {
        const { scrollTop, scrollHeight, clientHeight } = e.target;
        if (scrollTop + clientHeight >= scrollHeight - 50) {
          if (
            !isLoadingPaymentLinks &&
            fetchRazorpayPaymentsErr !== "Completed"
          ) {
            const nextSkip = skip + 10;
            setSkip(nextSkip);
            loadMore(nextSkip);
          }
        }
      }}
      expandable={{
        expandedRowRender: (record) => (
          <>
            <Text style={{ display: "block" }} strong>
              Payment Info
            </Text>
            <Text style={{ display: "block" }}>card_id:{record.card_id}</Text>
            <Text style={{ display: "block" }}>bank:{record.bank}</Text>
            <Text style={{ display: "block" }}>wallet:{record.wallet}</Text>
            <Text style={{ display: "block" }}>vpa:{record.vpa}</Text>
            <Text style={{ display: "block" }}>
              Description:{record.description}
            </Text>
          </>
        ),
        expandIcon: ({ expanded, onExpand, record }) =>
          expanded ? (
            <CaretDownFilled onClick={(e) => onExpand(record, e)} />
          ) : (
            <CaretRightFilled onClick={(e) => onExpand(record, e)} />
          ),
      }}
      scroll={{ y: 500, x: "100vw" }}
    />
  );
};

export default RazorpayPayments;
