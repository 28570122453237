import { Button, Dropdown, Flex, Popover, theme, Typography } from "antd";
import React, { useState } from "react";
import { CountdownCircleTimer } from "react-countdown-circle-timer";
import Strings from "../../Constants/Strings";
import { CaretDownOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
const { Title, Text } = Typography;

const ActionCenterCardHeader = ({
  action,
  calculateTimeRemaining,
  items,
  handleContinue,
  handleTrack,
}) => {
  const { useToken } = theme;
  const { token } = useToken();
  const { t } = useTranslation("home");
  const [timeRemaining, setTimeRemaining] = useState("");

  return (
    <>
      {action?.step <= 3 && action?.reservationFound ? (
        <Popover
          content={
            action?.countDown && timeRemaining !== 0
              ? `Your reserved slot booking will expire in: `
              : `Your reserved slots has expired.`
          }
          trigger={"hover"}
        >
          <Flex gap={8} align="center">
            <CountdownCircleTimer
              isPlaying
              duration={calculateTimeRemaining(action?.countDown)}
              colors={[token.Primary]}
              size={40}
              strokeWidth={2}
              onUpdate={(remainingTime) => action?.countDown ? setTimeRemaining(remainingTime) : setTimeRemaining(0)}
            >
              {({ remainingTime }) => {
                if (remainingTime <= 0 || !remainingTime) {
                  return <Text style={{ fontSize: "0.65em" }}>Expired</Text>;
                } else {
                  const minutes = String(
                    Math.floor(remainingTime / 60)
                  ).padStart(2, "0");
                  const seconds = String(remainingTime % 60).padStart(2, "0");
                  return (
                    <Text
                      style={{ fontSize: "0.65em" }}
                    >{`${minutes}:${seconds}`}</Text>
                  );
                }
              }}
            </CountdownCircleTimer>
            <Title style={{ fontSize: `clamp(1em, 3vw, 1.2em)`, margin: 0 }}>
              {t(`${action?.ActionTitle}`)}
            </Title>
          </Flex>
        </Popover>
      ) : (
        action?.step <= 3 && (
          <Title style={{ fontSize: `clamp(1em, 3vw, 1.2em)`, margin: 0 }}>
            {t(`${action?.ActionTitle}`)}
          </Title>
        )
      )}
      {action?.step > 3 && (
        <Dropdown
          menu={{
            items,
          }}
        >
          <Button
            iconPosition="end"
            style={{ fontSize: "1.2em", fontWeight: 600, padding: 0 }}
            size="small"
            type="text"
            icon={<CaretDownOutlined />}
          >
            {t(`${action?.ActionTitle}`)}
          </Button>
        </Dropdown>
      )}
      <Button
        size="small"
        onClick={() => {
          if (action?.step === 4) handleTrack(action.bookingID);
          else handleContinue(action);
        }}
        disabled={action?.isBoardingCenterMatched === true ? false : true}
      >
        {action?.step === 4
          ? t("track")
          : action?.step === 3 && action?.rescheduleBooking.count === 1
            ? t("Reschedule Booking")
            : t("continue_booking")}{" "}
        ❯
      </Button>
    </>
  );
};

export default ActionCenterCardHeader;
