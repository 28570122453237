import React, { useEffect, useState } from "react";
import styles from "../Styles/login.module.css";
import { useMediaQuery } from "react-responsive";
import { Button, Card, Form, Image, Input, Row, theme } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { signupAction } from "../State/actions";
import { forgotAction } from "../State/forgotactions";
import { useNavigate, useParams } from "react-router-dom";
import Spinner from "../../Spinner/Spinner";
import useWarnings from "../../Common/Components/useWarnings";

const ReVerify = () => {
    const isTab = useMediaQuery({ maxWidth: 576 });
    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const { useToken } = theme;
    const { token } = useToken();
    const { success, error, contextHolder } = useWarnings();
    const RESEND_TIMEOUT = 90; // Time in seconds
    const [timer, setTimer] = useState(0);

    console.log('object')

    const {
        requestOtpAccountRestoration,
        requestOtpAccountRestorationErr,
        verifyOtpAccountRestoration,
        verifyOtpAccountRestorationErr,
    } = useSelector((str) => str.signupReducer);
    const { reverifyToken, id } = useParams();
    const { isLoadingRequestOtp, isLoading } = useSelector(
        (str) => str.loadingReducer
    );
    const navigate = useNavigate();

    useEffect(() => {
        if (requestOtpAccountRestoration) {
            success(requestOtpAccountRestoration);
            dispatch(forgotAction.clearaccountRestorationOTPdata());
        } else if (requestOtpAccountRestorationErr) {
            error(requestOtpAccountRestorationErr);
            dispatch(forgotAction.clearaccountRestorationOTPdata());
        }
        if (verifyOtpAccountRestoration) {
            success(verifyOtpAccountRestoration);
            navigate("/login");
            // dispatch(forgotAction.clearaccountRestorationOTPdata());
        } else if (verifyOtpAccountRestorationErr) {
            error(verifyOtpAccountRestorationErr);
            dispatch(forgotAction.clearaccountRestorationOTPdata());
        }
    }, [
        requestOtpAccountRestoration,
        requestOtpAccountRestorationErr,
        verifyOtpAccountRestoration,
        verifyOtpAccountRestorationErr,
    ]);

    const onFinish = (data) => {
        dispatch(
            forgotAction.verifyOtpAccountRestorationEvent(id, reverifyToken, data)
        );
        form.resetFields();
    };

    const sendOTP = async () => {
        if (timer === 0) {
            dispatch(forgotAction.requestOtpAccountRestorationEvent(id))
            localStorage.setItem('otpResendStartTime', Date.now().toString()); // Reset expiry time
            setTimer(RESEND_TIMEOUT); // Reset timer
        }
    };

    useEffect(() => {
        // Check if a resend timer is already running
        const storedTime = localStorage.getItem('otpResendStartTime');
        if (storedTime) {
            const elapsedTime = Math.floor((Date.now() - parseInt(storedTime, 10)) / 1000);
            const remainingTime = Math.max(0, RESEND_TIMEOUT - elapsedTime);
            setTimer(remainingTime);
        }

        // Update the timer every second
        const interval = setInterval(() => {
            setTimer((prev) => (prev > 0 ? prev - 1 : 0));
        }, 1000);

        return () => clearInterval(interval); // Cleanup on component unmount
    }, []);

    return (
        <div
            className={styles.loginpage}
            style={{
                flexDirection: "column",
                backgroundImage: `url("/Assets/LoginAssets/loginscreen.png")`,
            }}
        >
            {contextHolder}
            <div style={{ width: isTab ? "" : "500px" }}>
                <Image
                    src={"/Assets/LoginAssets/logindogs.png"}
                    alt="dogs"
                    preview={false}
                />
                <Card
                    hoverable
                    style={{
                        width: isTab ? "100%" : "500px",
                        height: isTab ? "100%" : "",
                    }}
                >
                    <Form form={form} layout="vertical" onFinish={onFinish}>
                        <Form.Item
                            name={"otp"}
                            style={{ textAlign: "center" }}
                            rules={[
                                {
                                    required: true,
                                    message: "Please input your otp",
                                },
                            ]}
                        >
                            <Input.OTP />
                        </Form.Item>

                        <Form.Item style={{ textAlign: "center" }}>
                            <Button
                                htmlType="submit"
                                style={{
                                    width: "100%",
                                    height: "45px",
                                    backgroundColor: token.appbluecolor,
                                    color: token.White,
                                    fontSize: "18px",
                                }}
                                loading={isLoading}
                            >
                                Enter OTP
                            </Button>
                        </Form.Item>
                    </Form>
                    <Row justify={"space-between"}>
                        <Button
                            size="small"
                            type="link"
                            onClick={() => navigate("/login")}
                        >
                            Back to Login
                        </Button>
                        <Button
                            size="small"
                            type="link"
                            loading={isLoadingRequestOtp}
                            disabled={timer > 0}
                            style={{ textDecoration: "underline" }}
                            onClick={sendOTP}
                        >
                            {timer > 0 ? `Resend OTP in ${timer}s` : 'Send OTP'}
                        </Button>
                    </Row>
                </Card>
            </div>
        </div >
    );
};

export default ReVerify;
