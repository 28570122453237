import {
  startLoadingPaymentLinks,
  stopLoadingPaymentLinks,
} from "../../state/additionalLoadingActions";
import { fetchPaymentsApi } from "../utils/api";
import { fetchPaymentsConstants } from "./action-types";

const getPaymentsSuccess = (data) => {
  return {
    type: fetchPaymentsConstants.GET_ALL_PAYEMNTS_SUCCESS,
    data: data,
  };
};

const getPaymentsErr = (err) => {
  return {
    type: fetchPaymentsConstants.GET_ALL_PAYEMNTS_FAILURE,
    valuesError: err,
  };
};

const getPaymentsEvent = (page, search, sort) => {
  return (dispatch) => {
    dispatch(startLoadingPaymentLinks());
    fetchPaymentsApi
      .getPayments(page, search, sort)
      .then((response) => {
        dispatch(getPaymentsSuccess(response.data.info));
        dispatch(stopLoadingPaymentLinks());
      })
      .catch((err) => {
        dispatch(getPaymentsErr(err));
        dispatch(stopLoadingPaymentLinks());
      });
  };
};

const getRazorpayPaymentsSuccess = (data) => {
  return {
    type: fetchPaymentsConstants.GET_ALL_RAZORPAY_PAYEMNTS_SUCCESS,
    data: data,
  };
};

const getRazorpayPaymentsErr = (err) => {
  return {
    type: fetchPaymentsConstants.GET_ALL_RAZORPAY_PAYEMNTS_FAILURE,
    valuesError: err,
  };
};

const getRazorpayPaymentsEvent = (count, skip) => {
  return (dispatch) => {
    dispatch(startLoadingPaymentLinks());
    fetchPaymentsApi
      .getRazorpayPayments(count, skip)
      .then((response) => {
        dispatch(getRazorpayPaymentsSuccess(response.data.info));
        dispatch(stopLoadingPaymentLinks());
      })
      .catch((err) => {
        dispatch(getRazorpayPaymentsErr(err));
        dispatch(stopLoadingPaymentLinks());
      });
  };
};

export const fetchPaymentActions = {
  getPaymentsEvent,
  getRazorpayPaymentsEvent
};
