// // ThemeToggle.js
// import React, { createContext, useContext, useState } from 'react';
// import { ConfigProvider } from 'antd';
// import { lightTheme, darkTheme } from './themeconfig';

// const ThemeContext = createContext();

// export const useTheme = () => useContext(ThemeContext);

// const ThemeToggle = ({ children }) => {
//     const [isDarkMode, setIsDarkMode] = useState(false);


//     const toggleTheme = () => {
//         setIsDarkMode(!isDarkMode);
//     };

//     return (
//         <ThemeContext.Provider value={{ isDarkMode, toggleTheme }}>
//             <ConfigProvider theme={isDarkMode ? darkTheme : lightTheme}>
//                 {children}
//             </ConfigProvider>
//         </ThemeContext.Provider>
//     );
// };

// export default ThemeToggle;


import React, { createContext, useContext, useState, useEffect } from 'react';
import { ConfigProvider } from 'antd';
import { lightTheme, darkTheme } from './themeconfig';

const ThemeContext = createContext();

export const useTheme = () => useContext(ThemeContext);

const ThemeToggle = ({ children }) => {
    const [isDarkMode, setIsDarkMode] = useState(() => {
        // Parse the stored theme value from localStorage
        const savedTheme = localStorage.getItem("theme");
        return savedTheme ? JSON.parse(savedTheme) : false; // Default to light mode
    });

    const toggleTheme = () => {
        const newTheme = !isDarkMode;
        setIsDarkMode(newTheme);
        localStorage.setItem('theme', JSON.stringify(newTheme));
    };

    useEffect(() => {
        // Apply the initial theme to ensure consistency with localStorage
        localStorage.setItem('theme', JSON.stringify(isDarkMode));
    }, [isDarkMode]);

    return (
        <ThemeContext.Provider value={{ isDarkMode, toggleTheme }}>
            <ConfigProvider theme={isDarkMode ? darkTheme : lightTheme}>
                {children}
            </ConfigProvider>
        </ThemeContext.Provider>
    );
};

export default ThemeToggle;
