import { Button, Spin, Typography } from "antd";
import Card from "antd/es/card/Card";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Spinner from "../../Spinner/Spinner";
import { dashboardAction } from "../../Dashboard/State/actions";
import { addressAction } from "../../Common/State/adressactions";
const { Title } = Typography;

const LocationDetailsBody = ({ handleOk }) => {
  const { boardingcenterError, boardingcenter } = useSelector(
    (str) => str.addressReducer
  );
  const { isLoadingBoardingCenter } = useSelector((str) => str.loadingReducer);

  const onChange = (boardingAddress) => {
    dispatch(
      addressAction.updateBoardingCenterEvent({
        locationId: boardingAddress._id,
      })
    );
    handleOk();
  };

  const dispatch = useDispatch();
  const handleBoardingCenter = () => {
    if (boardingcenterError) {
      handleOk();
      dispatch(dashboardAction.getActionCenterDetailsEvent());
    }
  };

  if (isLoadingBoardingCenter) return <Spin />;

  return (
    <>
      {boardingcenter?.length > 1 && (
        <Title style={{ fontSize: "1.2em", marginBottom: "1.2em" }}>
          Please select a boarding location
        </Title>
      )}
      {boardingcenter?.length > 1 ? (
        <div>
          {boardingcenter?.map((boardingAddress) => (
            <>
              <Card
                hoverable
                style={{ marginBottom: "1.2em" }}
                onClick={() => onChange(boardingAddress)}
              >
                {boardingAddress?.completeAddress}
              </Card>
            </>
          ))}
        </div>
      ) : (
        !boardingcenterError && (
          <>
            <div
              style={{
                width: "350px",
                display: "inline-flex",
                fontSize: "1.2em",
              }}
            >
              Nearest Boarding center Available : <br />{" "}
              {boardingcenter?.completeAddress
                ? boardingcenter?.completeAddress
                : "Selected Address is not serviceable. Please select another location."}
            </div>
            <div style={{ textAlign: "end" }}>
              <Button
                type="primary"
                size="small"
                onClick={() => handleOk("actionreload")}
              >
                OK
              </Button>
            </div>
          </>
        )
      )}
      {boardingcenterError && (
        <>
          <div>{`${boardingcenterError?.message}. Please select another location to continue with our services.`}</div>
          <Button size="small" onClick={() => handleBoardingCenter()}>
            OK
          </Button>
        </>
      )}
    </>
  );
};

export default LocationDetailsBody;
