import { Button, Card, Col, Form, Input, Popconfirm, Row } from "antd";
import React from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { useSelector } from "react-redux";

const VerifyAccount = ({ handleDelete, loading }) => {
    const [emailForm] = Form.useForm();
    const { userDetails } = useSelector((str) => str.navbarReducer);

    return (
        <Row justify={"center"} style={{ alignItems: "center", height: "80vh" }}>
            <Col xs={22} sm={22} md={16} lg={12} xl={10}>
                <Card hoverable title={`${userDetails?.firstName} ${userDetails?.lastName}`}>
                    <Form layout="vertical" form={emailForm} onFinish={handleDelete}>
                        <Form.Item
                            label={
                                <span>
                                    Please type{" "}
                                    <span style={{ fontWeight: 500 }}>
                                        "DELETE {userDetails?.email}"
                                    </span>{" "}
                                </span>
                            }
                            name={"email"}
                            rules={[{ required: true, message: "Please enter the required field" }, ({ getFieldValue }) => ({
                                validator(_, value) {
                                    if (!value || getFieldValue('email') === `DELETE ${userDetails?.email}`) {
                                        return Promise.resolve();
                                    }
                                    return Promise.reject(new Error('The mentioned one do not match!'));
                                },
                            }),]}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item style={{ textAlign: "end" }}>
                            {/* <Popconfirm title='Are you sure you want to delete acccount?'> */}
                                <Button danger htmlType="submit" size="small" style={{ width: "100%" }} loading={loading}>
                                    DELETE MY ACCOUNT
                                </Button>
                            {/* </Popconfirm> */}
                        </Form.Item>
                    </Form>
                </Card>
            </Col>
        </Row>
    );
};

export default VerifyAccount;
