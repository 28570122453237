import { message, notification } from "antd";

const useWarnings = () => {
    const [messageApi, contextHolder] = notification.useNotification();
    const warning = () => {
        messageApi.open({
            type: "warning",
            content: "Please select a reason.",
        });
    };

    const otherWarning = () => {
        messageApi.open({
            type: "warning",
            content: "Please type your reason behind declining the booking.",
        });
    };

    const success = (content, placement = "top") => {
        // messageApi.open({
        //     type: "success",
        //     content: content,
        // })
        messageApi["success"]({
            message: content,
            placement: placement,
        });
    };

    const error = (content, placement = "top", duration) => {
        // messageApi.open({
        //     type: "error",
        //     content: content,
        // })
        messageApi["error"]({
            message: content,
            placement: placement,
            duration: duration
        });
    };

    const commonwarning = (content) => {
        messageApi.open({
            type: "warning",
            content: content,
        });
    };

    return {
        warning,
        otherWarning,
        contextHolder,
        success,
        error,
        commonwarning,
    };
};

export default useWarnings;
