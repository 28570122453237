import { CaretRightFilled } from "@ant-design/icons";
import { Button, Card, Progress, Typography } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { useNavigate } from "react-router-dom";
const { Title } = Typography;

const OtherActionCard = () => {
    const { t } = useTranslation();
    const { actionCenterDetails } = useSelector((str) => str.dashboardReducer);
    const isSmallScreen = useMediaQuery({ maxWidth: 576 });

    const navigate = useNavigate();
    return (
        <div style={{ marginLeft: isSmallScreen ? "" : "10px", marginRight: isSmallScreen ? "" : "10px" }}>
            <Card
                // title={t(`${actionCenterDetails?.userAction?.title}`)}
                style={{
                    borderRadius: 0,
                    minHeight: "250px",
                    overflow: "scroll",
                    borderColor: "lightgray"
                }}
                hoverable
            >
                <Title style={{ fontSize: "1.2em", marginBottom: 10 }}>
                    {t(`${actionCenterDetails?.userAction?.title}`)}
                </Title>
                {
                    actionCenterDetails && actionCenterDetails?.userAction?.actions?.map(e => (
                        <Button key={e?.message} size="small" style={{ display: "block",padding:0 }} type="link" onClick={() => navigate(e?.link)}>
                            {e?.message}
                        </Button>
                    ))
                }
            </Card>
        </div>
    );
};

export default OtherActionCard;
