import Lottie from "lottie-react";
import React from "react";
import internet from "../utils/internet.json";
import { Alert, Col, Row, Typography } from "antd";

const NoInternet = () => {
    return (
        <>
            <Alert
                message="No Internet Connection! Plese Check your Internet connection and try again!"
                type="error"
                closable={false}
                style={{textAlign:'center'}}
            />
            <div style={{ height: window.innerHeight, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                <Row>
                    <Col
                        xs={{ span: 12, offset: 6 }}
                        sm={{ span: 12, offset: 6 }}
                        md={{ span: 12, offset: 6 }}
                        lg={{ span: 12, offset: 6 }}
                    >
                        <Lottie animationData={internet} loop={true} />
                    </Col>
                </Row>
            </div>
        </>
    );
};

export default NoInternet;
