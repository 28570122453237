import {
  Button,
  FloatButton,
  Form,
  Input,
  Switch,
  Table,
  Tag,
  Typography,
} from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Spinner from "../Spinner/Spinner";
import { boardCenterAction } from "./State/actions";
import { v4 as uuidv4 } from "uuid";
import { services } from "../Common/Components/Services";
import {
  EditOutlined,
  MoneyCollectFilled,
  PlusOutlined,
} from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import useWarnings from "../Common/Components/useWarnings";
import { useRoute } from "../context/RouteContext";
import CommonModal from "../CommonModal/CommonModal";
import { boardCenterApi } from "./utils/api";
const { Text } = Typography;

/**
 * BoardingCenter component displays a list of boarding centers in a table format.
 * It provides options to edit existing centers or add a new one.
 */
const BoardingCenter = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const {
    allBoardcenters,
    updateBoardCenter,
    addBoardCenter,
    updateBoardCenterErr,
    addBoardCenterErr,
  } = useSelector((str) => str.boardCenterReducer);
  const {
    isLoadingAllBoardCenters,
    isLoading,
    isLoadingQuestionById,
    isLoadingUserDetails,
  } = useSelector((str) => str.loadingReducer);
  const [form] = Form.useForm();
  const { setSelectedKey } = useRoute();

  const navigate = useNavigate();
  const { success, error, contextHolder } = useWarnings();
  const [visible, setVisible] = useState(false);
  const onFinish = async (values) => {
    setLoading(true);
    try {
      const data = await boardCenterApi.updateAllBoardCenter({
        redeemCapPoints: {
          capAmount: values?.capAmount,
          isActive: true,
        },
      });
      if (data?.data?.info) {
        success("Updated successfully");
        dispatch(boardCenterAction.getAllBoardCentersEvent());
      }
      setLoading(false);
    } catch (error) {
      error("Something went wrong");
      setLoading(false);
    }
    setVisible(false);
  };

  useEffect(() => {
    dispatch(boardCenterAction.getAllBoardCentersEvent());
  }, [updateBoardCenter, addBoardCenter]);

  useEffect(() => {
    setSelectedKey("CRUD_BOARDING_CENTE");
  }, []);

  if (
    isLoadingAllBoardCenters ||
    isLoading ||
    isLoadingQuestionById ||
    isLoadingUserDetails
  ) {
    return <Spinner />;
  }

  const columns = [
    {
      title: "Board center details",
      key: uuidv4(),
      width: "20%",
      render: ({ _id, name, code_word }) => (
        <>
          <Text style={{ display: "block" }}>
            {name}-{code_word}
          </Text>
          <Text style={{ display: "block" }}>{_id}</Text>
        </>
      ),
    },
    {
      title: "Boardcenter head and id",
      key: uuidv4(),
      width: "20%",
      render: ({ boardCenterHeadName, boardCenterHeadId }) => (
        <Text style={{ display: "block" }}>
          {boardCenterHeadName}-{boardCenterHeadId}
        </Text>
      ),
    },
    {
      title: "Location",
      dataIndex: "address",
      key: "address",
      width: "30%",
    },
    {
      title: "Services and its price",
      width: "20%",
      render: ({ servicesPresent }) =>
        servicesPresent?.map((e) => (
          <Tag>
            {services.find((ser) => ser.key === e.name).name}-{e.price}
          </Tag>
        )),
    },
    {
      title: "Action",
      width: "10%",
      render: ({ boardCenterUniqueId }) => (
        <>
          <Switch size="small" />
          <EditOutlined
            style={{ fontSize: "20px", marginLeft: "10px", cursor: "pointer" }}
            onClick={() => navigate(`/boardingCenter/${boardCenterUniqueId}`)}
          />
        </>
      ),
    },
  ];
  const handleCancel = () => setVisible(false);

  return (
    <>
      {contextHolder}
      <Table
        columns={columns}
        bordered
        size="small"
        dataSource={allBoardcenters}
        pagination={false}
        title={() => (
          <Text strong>Global Cap amount: {allBoardcenters?.[0]?.globalCapAmount || "--"}</Text>
        )}style={{textAlign: "center"}}
      />
      <CommonModal
        body={() => {
          return (
            <Form layout="vertical" form={form} onFinish={onFinish}>
              <Form.Item
                rules={[{ required: true }]}
                name={"capAmount"}
                label="Enter global cap amount for rewards"
              >
                <Input type="number" />
              </Form.Item>
              <Form.Item>
                <Button htmlType="submit" type="primary" loading={loading}>
                  Submit
                </Button>
              </Form.Item>
            </Form>
          );
        }}
        isModalOpen={visible}
        handleCancel={handleCancel}
        handleOk={handleCancel}
      />
      <FloatButton.Group
        shape="circle"
        trigger="click"
        style={{
          insetInlineEnd: 24,
        }}
      >
        <FloatButton
          icon={<PlusOutlined />}
          tooltip={"Add a BC"}
          onClick={() => navigate("/addBoardingCenter")}
          type="primary"
          style={{
            right: 30,
          }}
        />
        <FloatButton
          icon={<MoneyCollectFilled />}
          tooltip={"Change global cap amount for rewards"}
          onClick={() => setVisible(true)}
          type="primary"
          style={{
            right: 30,
          }}
        />
      </FloatButton.Group>
    </>
  );
};

export { BoardingCenter };
