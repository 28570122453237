export const signupinConstants = {
  LOGIN_SUCCESS: "LOGIN_SUCCESS",
  LOGIN_FAILURE: "LOGIN_FAILURE",

  SIGNUP_SUCCESS: "SIGNUP_SUCCESS",
  SIGUP_FAILURE: "SIGUP_FAILURE",

  VERIFY_SUCCESS: "VERIFY_SUCCESS",
  VERIFY_ERROR: "VERIFY_ERROR",

  FORGOT_PASSWORD_SUCCESS: "FORGOT_PASSWORD_SUCCESS",
  FORGOT_PASSWORD_FAILURE: "FORGOT_PASSWORD_FAILURE",

  CHANGE_PASSWORD_SUCCESS: "CHANGE_PASSWORD_SUCCESS",
  CHANGE_PASSWORD_FAILURE: "CHANGE_PASSWORD_FAILURE",

  LOGIN_GOOGLE_SUCCESS: "LOGIN_GOOGLE_SUCCESS",
  LOGIN_GOOGLE_FAILURE: "LOGIN_GOOGLE_FAILURE",

  LOGIN_GOOGLE_DATA_RESET:"LOGIN_GOOGLE_DATA_RESET",

  SIGNUP_RESET: "SIGNUP_RESET",

  REQUEST_OTP_SUCCESS: "REQUEST_OTP_SUCCESS",
  REQUEST_OTP_FAILURE: "REQUEST_OTP_FAILURE",

  VERIFY_OTP_SUCCESS: "VERIFY_OTP_SUCCESS",
  VERIFY_OTP_ERROR: "VERIFY_OTP_ERROR",

  REQUEST_OTP_SUCCESS_ACCOUNT_RESTORATION: "REQUEST_OTP_SUCCESS_ACCOUNT_RESTORATION",
  REQUEST_OTP_FAILURE_ACCOUNT_RESTORATION: "REQUEST_OTP_FAILURE_ACCOUNT_RESTORATION",

  VERIFY_OTP_FAILURE_ACCOUNT_RESTORATION: "VERVERIFY_OTP_FAILURE_ACCOUNT_RESTORATIONIFY_OTP_SUCCESS",
  VERIFY_OTP_SUCCESS_ACCOUNT_RESTORATION: "VERIFY_OTP_SUCCESS_ACCOUNT_RESTORATION",

  CLEAR_OTP_DATA:'CLEAR_OTP_DATA',

  CLEAR_ACCOUNT_RESTORATION_OTP_DATA:"CLEAR_ACCOUNT_RESTORATION_OTP_DATA"
};
