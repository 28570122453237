import { Button, Col, Form, theme } from "antd";
import React from "react";
import { useNavigate } from "react-router-dom";
import { handleFinish } from "../utils/handleFinishDetails";
import { useDispatch, useSelector } from "react-redux";

const DetailsButtons = ({ form, address, dateTime }) => {
  const { useToken } = theme;
  const { token } = useToken();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { bookappointmentdetailsbyID } = useSelector(
    (str) => str.bookAppointmentReducer
  );
  return (
    <Col
      md={24}
      lg={16}
      style={{
        marginTop: "1.2em",
        marginBottom: "1.2em",
        position: "sticky",
        bottom: 10,
        justifyContent: "flex-end",
        display: "flex",
        background: token.background,
      }}
    >
      <Button
        type="primary"
        onClick={() => {
          localStorage.setItem("newBookAppointment", 'false');
          navigate("/book-appointment/step-1");
        }}
        style={{ marginRight: "1.2em" }}
      >
        ❮ Previous
      </Button>
      <Form
        form={form}
        onFinish={() =>
          handleFinish(
            address,
            form,
            bookappointmentdetailsbyID,
            navigate,
            dispatch,
            dateTime
          )
        }
      >
        <Button
          type="primary"
          htmlType="submit"
          disabled={
            !bookappointmentdetailsbyID?.services?.some(
              (service) =>
                dateTime[service?.serviceName]?.checkInDateTime &&
                dateTime[service?.serviceName]?.checkOutDateTime
            )
          }
        >
          Next ❯
        </Button>
      </Form>
    </Col>
  );
};

export default DetailsButtons;
