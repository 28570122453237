import { Button, Col, Dropdown, Input, Row, Typography } from "antd";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { fetchPaymentActions } from "../State/actions";
import { DownOutlined } from "@ant-design/icons";
const { Text } = Typography;

const PaymentHeader = ({ searchClientFunc, setPage }) => {
    const [searchParams] = useSearchParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const { totalFetchPayments } = useSelector((str) => str.fetchPaymentsReducer);
    const items = [
        {
            key: "createdAt",
            label: <Text>Created At</Text>,
        },
        {
            key: "firstName",
            label: <Text>First Name</Text>,
        },
        {
            key: "lastName",
            label: <Text>Last Name</Text>,
        },
        {
            key: "email",
            label: <Text>Email</Text>,
        },
        {
            key: "paymentStatus",
            label: <Text>Payment status</Text>,
        },
        {
            key: "subscriptionStatus",
            label: <Text>Subscription status</Text>,
        }
    ];

    const onClick = ({ key }) => {
        const searchParams = new URLSearchParams(location.search);
        searchParams.set("sort", key);
        navigate(`?${searchParams.toString()}`);
        setPage(1);
        dispatch(
            fetchPaymentActions.getPaymentsEvent(
                1,
                searchParams.get("search"),
                key,
            )
        );
    };



    return (
        <Row style={{ justifyContent: "space-between", alignItems: "center" }}>
            <Col>
                <Button
                    size="small"
                    type="primary"
                    disabled={searchParams.size === 0}
                    onClick={() => {
                        navigate(`/payments`);
                        setPage(1);
                        dispatch(fetchPaymentActions.getPaymentsEvent(1));
                    }}
                >
                    Clear Filters
                </Button>
            </Col>
            <Col>
                <Row>
                    <Col style={{ marginRight: "10px" }}>
                        <Text>Total users: {totalFetchPayments || "--"}</Text>
                    </Col>
                    <Col style={{ marginRight: "10px" }}>
                        <Dropdown
                            menu={{
                                items,
                                onClick,
                            }}
                            style={{ cursor: "pointer" }}
                        >
                            <Button iconPosition="end" icon={<DownOutlined />} size="small">
                                {`Sort By: ${searchParams.get("sort")
                                    ? searchParams.get("sort")
                                    : "createdAt"
                                    }`}
                            </Button>
                        </Dropdown>
                    </Col>
                    <Col>
                        <Input
                            placeholder="Search user details, role..."
                            allowClear
                            id="search"
                            size="small"
                            onChange={(e) => {
                                e.target.value.trim().length > 0 &&
                                    searchClientFunc(e.target.value);
                            }}
                        />
                    </Col>
                </Row>
            </Col>
        </Row>
    );
};

export default PaymentHeader;

// import { Button, Col, Dropdown, Input, Row, Space, Typography } from "antd";
// import React from "react";
// import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
// import { usersAction } from "../State/actions";
// import { useDispatch, useSelector } from "react-redux";
// import { DownOutlined } from "@ant-design/icons";
// const { Text } = Typography;

// const UserHeader = ({ searchClientFunc, setPage, setFiltered }) => {
//     const [searchParams] = useSearchParams();
//     const dispatch = useDispatch();
//     const navigate = useNavigate();
//     const location = useLocation();
//     const { totalUsers } = useSelector((state) => state.usersReducer);

//     const onClick = ({ key }) => {
//         const searchParams = new URLSearchParams(location.search);
//         searchParams.set("sort", key);
//         navigate(`?${searchParams.toString()}`);
//         setPage(1);
//         dispatch(
//             usersAction.getUsersEvent(
//                 1,
//                 searchParams.get("search"),
//                 key,
//                 searchParams.get("systemPasswordOnly")
//             )
//         );
//     };

//     return (
//         <Row style={{ justifyContent: "space-between", alignItems: "center" }}>
//             <Col>
//                 <Button
//                     size="small"
//                     type="primary"
//                     disabled={searchParams.size === 0}
//                     onClick={() => {
//                         navigate(`/users`);
//                         setPage(1);
//                         setFiltered({ systemPasswordOnly: false });
//                         dispatch(usersAction.getUsersEvent(1));
//                     }}
//                 >
//                     Clear Filters
//                 </Button>
//             </Col>
//             <Col>
//                 <Row>
//                     <Col style={{ marginRight: "10px" }}>
//                         <Text>Total users: {totalUsers || "--"}</Text>
//                     </Col>
//                     <Col style={{ marginRight: "10px" }}>
//                         <Dropdown
//                             menu={{
//                                 items,
//                                 onClick,
//                             }}
//                             style={{ cursor: "pointer" }}
//                         >
//                             <Button iconPosition="end" icon={<DownOutlined />} size="small">
//                                 {`Sort By: ${searchParams.get("sort")
//                                     ? searchParams.get("sort")
//                                     : "updatedAt"
//                                     }`}
//                             </Button>
//                         </Dropdown>
//                     </Col>
//                     <Col>
//                         <Input
//                             placeholder="Search user details, role..."
//                             allowClear
//                             id="search"
//                             size="small"
//                             onChange={(e) => {
//                                 e.target.value.trim().length > 0 &&
//                                     searchClientFunc(e.target.value);
//                             }}
//                         />
//                     </Col>
//                 </Row>
//             </Col>
//         </Row>
//     );
// };

// export default UserHeader;
