
const base64ToUint8Array = (base64String) => {
  const binaryString = atob(base64String.replace(/-/g, "+").replace(/_/g, "/"));
  const binaryLength = binaryString.length;
  const bytes = new Uint8Array(binaryLength);
  for (let i = 0; i < binaryLength; i++) {
    bytes[i] = binaryString.charCodeAt(i);
  }
  return bytes;
};

const verifySignature = (token) => {
  if (!token) return false;
  return verifyToken(token);
};

const verifyToken = async (token) => {
  try {
    const publicKey = process.env.REACT_APP_PUBLIC_KEY_FOR_TOKEN;

    const [header, payload, signature] = token.split(".");
    const unsignedToken = `${header}.${payload}`;

    // Decode base64url encoded signature into Uint8Array
    const decodedSignature = base64ToUint8Array(signature);

    // Convert PEM public key to ArrayBuffer
    const publicKeyContent = publicKey.replace(/-----\w+ PUBLIC KEY-----/g, "").trim();
    const publicKeyBuffer = Uint8Array.from(atob(publicKeyContent), (c) =>
      c.charCodeAt(0)
    );

    // Import the public key to CryptoKey
    const publicKeyObject = await crypto.subtle.importKey(
      "spki",
      publicKeyBuffer.buffer,
      { name: "RSASSA-PKCS1-v1_5", hash: "SHA-256" },
      false,
      ["verify"]
    );

    // Verify the signature
    const isSignatureValid = await crypto.subtle.verify(
      "RSASSA-PKCS1-v1_5",
      publicKeyObject,
      decodedSignature,
      new TextEncoder().encode(unsignedToken)
    );

    console.log(isSignatureValid, "isSignatureValid");
    return isSignatureValid;
  } catch (error) {
    throw new Error("Invalid Token");
  }
};

module.exports = { verifySignature };
